<div class="full-screen">
  <div class="header-items">
    <div class="container navbar-header-items">

      <div class="row">
        <div class="col-12">
          <h2>Test Time</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-1">
          <h2><b></b></h2>
          <p>Please, stay focused & good luck :)</p>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-2">
          <img class="progress-image" src="assets/medal.png" />
        </div>

        <div class="col-5">
          <div class="progress" style="height: 7px;">
            <div class="progress-bar progress-header-test" role="progressbar" [style.width.%]="test_step/test_steps*100" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>

        <div class="col-3 progress-point">{{test_step}} / {{test_steps}}</div>

        <div class="col-2 icon-action">
          <fa-icon [icon]="['fas','home']" size="2x" (click)="redirect('')"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="main-screen-items container">
    <div class="row items-row-test-title">
      <div class="col-12 items-col">
        <h6>{{test_question}}</h6>
      </div>
    </div>

    <div class="row items-row-test">
      <div class="col-12 items-col" (click)="validUserAnswer(test_img_src_1_correct_anw); answerImg1 = true;" align="center">
        <img [src]="test_img_src_1" alt="image" [ngClass]="answerImg1 && test_img_src_1_correct_anw === 0 ? 'item-answer-invalid'  : ''" />
      </div>
    </div>

    <div class="row items-row-test">
      <div class="col-12 items-col" (click)="validUserAnswer(test_img_src_2_correct_anw); answerImg2 = true;" align="center">
        <img [src]="test_img_src_2" alt="image" [ngClass]="answerImg2 && test_img_src_2_correct_anw === 0 ? 'item-answer-invalid'  : ''" />
      </div>
    </div>
  </div>
</div>




