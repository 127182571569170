<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="8"
  [test_question]="'8. Most common employee name(women)?'"
  [test_img_src_1]="'./assets/items-img/culture_test_time/Monika.png'"
  [test_img_src_1_correct_anw]="0"
  [test_img_src_2]="'./assets/items-img/culture_test_time/Andrea.png'"
  [test_img_src_2_correct_anw]="1"
  [test_success_redirect]="'branch'"
></app-unit-company-culture-test>
