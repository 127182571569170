import { Component, OnInit } from '@angular/core';
import {User, UserPosition} from '../../../../../interfaces/interfaces';
import {Router} from '@angular/router';
import {UserService} from '../../../../../services/user.service';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faArrowCircleLeft, faUser, faUserEdit, faUserTie} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  userList: User[] = [];

  constructor(private _router: Router,
              private _userService: UserService,
              private _library: FaIconLibrary) {

    _library.addIcons(faUser);
    _library.addIcons(faUserEdit);
    _library.addIcons(faArrowCircleLeft);
  }

  ngOnInit(): void {
    this._userService.getAllUsers().subscribe( data => {
      this.userList = data.filter(obj => obj.role === 'USER');
    });
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

}
