<div class="full-screen">
  <app-admin-header></app-admin-header>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="align-items: flex-end;">
      <div class="modal-content">
        <div class="modal-body">
          <p class="secondary-color-test">Do you want to delete selected team?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn items-button" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn items-button" data-bs-dismiss="modal" (click)="deleteTeam()">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdropAlert" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropAlertLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="align-items: flex-end;">
      <div class="modal-content">
        <div class="modal-body">
          <p class="secondary-color-test">The team is assigned to existing users, it cannot be deleted.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn items-button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="container main-container mt-3">
    <form [formGroup]="teamForm">

      <div class="row items-admin-row-p">

        <div class="col-8">
          <div class="row items-admin-row-p mt-2">
            <div class="col-12">
              <p><span class="requiredInput" *ngIf="editedMode">*</span>Name:</p>
            </div>
          </div>

          <div class="row items-admin-row-p">
            <div class="col-12" *ngIf="!editedMode">
              <h4>{{teamData?.team.name}}</h4>
            </div>

            <div class="col-12" *ngIf="editedMode">
              <input id="name" type="text" class="form-control" formControlName="name">
              <div *ngIf="formControls.name.invalid && (formControls.name.dirty || formControls.name.touched)" class="error">
                <div *ngIf="formControls.name.errors.required">Name is required.</div>
              </div>
            </div>
          </div>

          <div class="row items-admin-row-p">
            <div class="col-12">
              <p><span *ngIf="editedMode" class="requiredInput">*</span>Description:</p>
            </div>
          </div>

          <div class="row items-admin-row-p">
            <div class="col-12" *ngIf="!editedMode">
              <h6>{{teamData?.team.description}}</h6>
            </div>

            <div class="col-12" *ngIf="editedMode">
              <textarea id="description" type="text" class="form-control" formControlName="description"></textarea>
              <div *ngIf="formControls.description.invalid && (formControls.description.dirty || formControls.description.touched)" class="error">
                <div *ngIf="formControls.description.errors.required">Description is required.</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <img class="items-admin-row-img" src="../../../../../../../../assets/admin-img/icon-team2.png" alt="image" />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h5><b>Team information</b></h5>
        </div>
      </div>

      <div class="row items-admin-row-p" *ngIf="teamData && teamData.users.length > 0">
        <div class="col-12">
          <h6 class="title-group"><b>Team users</b></h6>
        </div>
      </div>

      <div class="row items-admin-row-p" *ngIf="teamData && teamData.users.length > 0">
        <div class="col-12">
          <ul>
            <li *ngFor="let user of teamData.users">
              <h6> {{user.first_name}} {{user.last_name}}</h6>
            </li>
          </ul>
        </div>
      </div>

      <div class="row mt-4" *ngIf="teamFunFact">
        <div class="col-12">
          <h6 class="title-group"><b>Team fun fact</b></h6>
        </div>
      </div>

      <div class="row items-admin-row-p" *ngIf="teamFunFact">
        <div class="col-12">
          <p>Name:</p>
        </div>
      </div>

      <div class="row items-admin-row-p" *ngIf="teamFunFact">
        <div class="col-12" *ngIf="!editedMode">
          <h6>{{teamFunFact?.name}}</h6>
        </div>

        <div class="col-12" *ngIf="editedMode">
          <input id="teamFunFact_name" type="text" class="form-control" formControlName="teamFunFact_name">
          <div *ngIf="formControls.teamFunFact_name.invalid && (formControls.teamFunFact_name.dirty || formControls.teamFunFact_name.touched)" class="error">
            <div *ngIf="formControls.teamFunFact_name.errors.required">Name is required.</div>
          </div>
        </div>
      </div>

      <div class="row items-admin-row-p" *ngIf="teamFunFact">
        <div class="col-12">
          <p>Description:</p>
        </div>
      </div>

      <div class="row items-admin-row-p" *ngIf="teamFunFact">
        <div class="col-12" *ngIf="!editedMode">
          <h6>{{teamFunFact?.description}}</h6>
        </div>

        <div class="col-12" *ngIf="editedMode">
          <textarea id="teamFunFact_desc" type="text" class="form-control" formControlName="teamFunFact_desc"></textarea>
          <div *ngIf="formControls.teamFunFact_desc.invalid && (formControls.teamFunFact_desc.dirty || formControls.teamFunFact_desc.touched)" class="error">
            <div *ngIf="formControls.teamFunFact_desc.errors.required">Description is required.</div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h6 class="title-group"><b>Team members</b></h6>
        </div>
      </div>

      <div class="row items-admin-row-p" *ngIf="!teamData.members || teamData.members.length < 4">
        <div class="col-12">
          <p class="title-group">Please provide the details of at least three colleagues in the team (Add member)</p>
        </div>
      </div>

      <div *ngIf="teamData">
        <div class="row items-row-box" *ngFor="let member of teamData.members">
          <div class="col-3 items-col-img">
            <img class="" src="{{ actURL + member.image }}" alt="{{ member.image }}">
          </div>

          <div class="col-9 items-col">
            <div class="row mb-1">
              <div class="col-12"><b>{{member.name}}</b><fa-icon class="icon" [icon]="['fab', 'linkedin']"></fa-icon></div>
            </div>

            <div class="row">
              <div class="col-12 position">{{member.position}}</div>
            </div>

            <div class="row">
              <div class="col-12">{{member.description}}</div>
            </div>

            <div class="row" *ngIf="editedMode">
              <div class="col" align="right">
                <fa-icon (click)="deleteTeamMember(member.id)" class="icon-trash" [icon]="['fas', 'trash']"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div *ngIf="editedMode" class="col-12" align="right">
          <button id="member_page" type="button" class="btn items-button" [routerLink]="['/admin-team-add-member', teamData.team.id]" routerLinkActive="active">Add member</button>
          <button *ngIf="!teamFunFact" id="fact_page" type="button" class="btn items-button" [routerLink]="['/admin-team-add-fact', teamData.team.id]">Add fun fact</button>
        </div>
      </div>

      <div class="row row-footer">
        <div class="col-4" align="left">
          <button *ngIf="editedMode" id="back_page" type="button" class="btn items-button" (click)="editMode()">
            <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
          </button>
          <button *ngIf="!editedMode" id="back_page_ed" type="button" class="btn items-button" (click)="redirect('admin-teams')">
            <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
          </button>
        </div>

        <div *ngIf="!editedMode" class="col-8" align="right">
          <button id="member_page_new" type="button" class="btn items-button"
                  *ngIf="!teamData.members || teamData.members.length < 4"
                  [routerLink]="['/admin-team-add-member', teamData.team.id]" routerLinkActive="active">
            Add member
          </button>
          <button id="edit_page" type="button" class="btn items-button" (click)="editMode()">Edit team</button>
        </div>

        <div *ngIf="editedMode" class="col-8" align="right">
          <button *ngIf="teamData.users.length === 0" id="delete_page" type="button" class="btn items-button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Delete team</button>
          <button *ngIf="teamData.users.length > 0" id="delete_page_alert" type="button" class="btn items-button" data-bs-toggle="modal" data-bs-target="#staticBackdropAlert">Delete team</button>
          <button id="save_page" type="button" class="btn items-button" (click)="updateTeam()" [disabled]="teamForm.invalid">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>
