<div class="full-screen">

  <div class="header">
    <div class="container">
      <div class="row items-row-narrow-title">
        <app-header></app-header>
      </div>
    </div>
  </div>

  <div class="main-screen">
    <div class="container">

      <div class="row items-row-narrow-title">
        <div class="col-12 items-col text-center">
          <h1>How to use</h1>
          <img class="test" src="./../../../../assets/screen_app_how_to_use.png" alt="image" />
        </div>
      </div>


    </div>
  </div>

  <div class="footer">
    <div class="container">
      <div class="row items-row-narrow-title">
        <div class="col-12" align="center">
          <fa-icon class="icon" [icon]="['fas','circle']" size="l"></fa-icon>
          <fa-icon class="icon" [icon]="['fas','circle']" size="l"></fa-icon>
          <fa-icon class="icon" [icon]="['fas','circle']" size="l"></fa-icon>
          <fa-icon class="icon" [icon]="['far','dot-circle']" size="l"></fa-icon>
        </div>
      </div>

      <div class="row items-row-narrow-footer">
        <div class="col-6" align="left">
          <button id="skip" type="button" class="btn items-button" (click)="redirect('')">Skip</button>
        </div>

        <div class="col-6" align="right">
          <button id="next_page" type="button" class="btn items-button" (click)="redirect('')">Start</button>
        </div>
      </div>
    </div>
  </div>




