import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Step, UserData} from '../../interfaces/interfaces';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faUser, faQuestion, faCheck, faStar, faLock, faLockOpen} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../environments/environment';
import {AuthService} from "../../services/auth.service";

const itemsConfig = environment.allConfigItems;

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnChanges {
  userData: UserData;
  accessUserItems: Step[] = [];
  allConfigItems: Step[] = itemsConfig;

  constructor(private router: Router,
              private library: FaIconLibrary,
              private _userService: UserService,
              private _authService: AuthService) {

    library.addIcons(faUser);
    library.addIcons(faQuestion);
    library.addIcons(faCheck);
    library.addIcons(faStar);
    library.addIcons(faLock);
    library.addIcons(faLockOpen);
  }

  ngOnInit(): void {
    this.subsNewUserData();

    this._userService.getActUser().subscribe( data => {
      this.userData = data;
      this._authService.setUserInfo(data);

      this.setAccessMenuItem();
    });

    // this.userData = this._userService.getActUserData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setAccessMenuItem();
  }

  subsNewUserData() {
    this._userService.userDataActNotifier$.subscribe( (userData: UserData) => {
      this.userData = userData;
      this.setAccessMenuItem();
    });
  }

  setAccessMenuItem(){
    if (this.userData && this.userData.user_steps && this.userData.user_steps.length > 0) {
      this.accessUserItems = this.allConfigItems.filter(
        item => this.userData.user_steps.some(userItem => userItem.step_id == item.id)
      );
    }
  }

  getActualStepStatus(step_id: string) {
    if (this.userData.user_steps.length > 0 ) {
      return this.userData.user_steps.find(item => item.step_id == step_id)?.step_status;
    }
    return 0;
  }

  redirect(path: string, status: number) {
    if (status === 0) {
      return;
    }
    this.router.navigate([path]);
  }
}
