import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit-company-culture-test-popular-woman-name',
  templateUrl: './unit-company-culture-test-popular-woman-name.component.html',
  styleUrls: ['./unit-company-culture-test-popular-woman-name.component.scss']
})
export class UnitCompanyCultureTestPopularWomanNameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
