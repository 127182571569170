import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-main-error-page',
  templateUrl: './main-error-page.component.html',
  styleUrls: ['./main-error-page.component.scss']
})
export class MainErrorPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
