import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faChartLine, faUser, faUsers} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-main-menu',
  templateUrl: './admin-main-menu.component.html',
  styleUrls: ['./admin-main-menu.component.scss']
})
export class AdminMainMenuComponent implements OnInit {

  menuList: any = [
    {name: '1. Teams', path: 'admin-teams', iconName: 'users'},
    {name: '2. Users', path: 'admin-users', iconName: 'user'}
  ];

  constructor(private _router: Router,
              private _library: FaIconLibrary) {

    _library.addIcons(faUsers);
    _library.addIcons(faUser);
    _library.addIcons(faChartLine);
  }

  ngOnInit(): void {
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

}
