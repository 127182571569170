import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../../../../services/user.service';
import {Actions, UserActionScheduled} from '../../../../../../../interfaces/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faArrowCircleLeft, faCheck, faClock, faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss']
})
export class UserActionsComponent implements OnInit {
  selectedAction: number;
  user_external_id: string;
  actions: Actions[] = [];
  actionsScheduled: UserActionScheduled[] = [];

  constructor(private _userService: UserService,
              private _library: FaIconLibrary,
              private _route: ActivatedRoute,
              private _router: Router) {

    _library.addIcons(faTrash);
    _library.addIcons(faCheck);
    _library.addIcons(faClock);
    _library.addIcons(faArrowCircleLeft);
  }

  ngOnInit(): void {
    this.user_external_id = this._route.snapshot.params.id;

    this._userService.getActions().subscribe( data => {
      this.actions = data;
    });

    this._userService.getAllUserActionsScheduled(this.user_external_id).subscribe(data => {
      this.actionsScheduled = data;
    });
  }

  setSelectedAction(id) {
    this.selectedAction = id;
  }

  deleteScheduledAction() {
    this._userService.deleteAction(this.selectedAction).subscribe(resp => {
      this.actionsScheduled = this.actionsScheduled.filter(action => action.id != this.selectedAction.toString());
    });
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

  redirectBack() {
    this.redirect('admin-user/' + this._route.snapshot.params.id);
  }
}
