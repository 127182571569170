import { Component, OnInit } from '@angular/core';
import {TeamService} from '../../../../../../../services/team.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {TeamData, UserData} from '../../../../../../../interfaces/interfaces';

@Component({
  selector: 'app-new-team-fun-fact',
  templateUrl: './new-team-fun-fact.component.html',
  styleUrls: ['./new-team-fun-fact.component.scss']
})
export class NewTeamFunFactComponent implements OnInit {

  teamForm = this._formBuilder.group({
    name: '',
    description: '',
  });

  constructor(private _teamService: TeamService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _formBuilder: FormBuilder,
              private _library: FaIconLibrary) { }

  ngOnInit(): void {}

  addFunFact() {
    const formData: FormData = new FormData();

    formData.append('team_id', this._route.snapshot.params.id);
    formData.append('name', this.teamForm.controls.name.value);
    formData.append('description', this.teamForm.controls.description.value);

    this._teamService.createTeamFunFact(formData).subscribe((data: UserData) => {
      this.redirect('admin-teams');
    });
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

}
