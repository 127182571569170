import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {TeamData, UserData} from '../../../../interfaces/interfaces';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faArrowDown, faCircle} from '@fortawesome/free-solid-svg-icons';
import {TeamService} from '../../../../services/team.service';
import {UserService} from '../../../../services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StepService} from '../../../../services/step.service';
import {environment} from '../../../../../environments/environment';
import {saveAs} from 'file-saver';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';


const URL = environment.apiEndpoint;

@Component({
  selector: 'app-unit-team-and-i',
  templateUrl: './unit-team-and-i.component.html',
  styleUrls: ['./unit-team-and-i.component.scss']
})
export class UnitTeamAndIComponent implements OnInit {

  userData: UserData;
  teamData: TeamData = {};

  currentFile: File;
  currentVideo: File;

  dataForm: FormGroup;
  actURL: string = URL;

  constructor(private router: Router,
              private fb: FormBuilder,
              public library: FaIconLibrary,
              private teamService: TeamService,
              private userService: UserService,
              private stepService: StepService) {

    library.addIcons(faCircle);
    library.addIcons(faArrowDown);
    library.addIcons(faLinkedin);
  }

  ngOnInit(): void {
    this.initForm();
    this.loadUserData();
    this.subsNewUserData();
  }

  initForm() {
    this.dataForm = this.fb.group({
      hobby: ['', [Validators.required, Validators.minLength(10)]],
      personal_info: ['', [Validators.required, Validators.minLength(10)]],
      favoriteAnimal: ['', Validators.required],
      favoriteColor: ['', Validators.required],
      favoriteDrink: ['', Validators.required],
      favoriteTree: ['', Validators.required],
      favoritePlace: ['', Validators.required],
      favoriteGame: ['', Validators.required],
    });
  }

  loadUserTeam() {
    if (this.userData) {
      this.teamService.getUserTeamById(this.userData.user_info.external_id).subscribe(data => {
        this.teamData = data;
      });
    }
  }

  loadUserData() {
    this.userData = this.userService.getActUserData();

    console.log(this.userData);

    this.loadUserTeam();
    this.setDataFormValues();
  }

  subsNewUserData() {
    this.userService.userDataActNotifier$.subscribe( (userData: UserData) => {
      this.userData = userData;
      this.loadUserTeam();
    });
  }

  selectFile(event): void {
    this.currentFile = event.target.files[0];
  }

  selectVideo(event): void {
    this.currentVideo = event.target.files[0];
  }

  upload(): void {
    const formData: FormData = new FormData();

    formData.append('hobby', this.dataForm.controls.hobby.value);
    formData.append('personal_info', this.dataForm.controls.personal_info.value);
    formData.append('image', this.currentFile);
    formData.append('video', this.currentVideo);
    formData.append('external_id', this.userData.user_info.external_id);
    formData.append('favoriteAnimal', this.dataForm.controls.favoriteAnimal.value);
    formData.append('favoriteColor', this.dataForm.controls.favoriteColor.value);
    formData.append('favoriteDrink', this.dataForm.controls.favoriteDrink.value);
    formData.append('favoriteTree', this.dataForm.controls.favoriteTree.value);
    formData.append('favoritePlace', this.dataForm.controls.favoritePlace.value);
    formData.append('favoriteGame', this.dataForm.controls.favoriteGame.value);

    this.stepService.setUserPersonalInfo(formData).subscribe((data: UserData) => {
        if (data) {
          this.userService.saveActUser(data);
        }

        this.router.navigate(['']);
      },
      err => {
        this.currentFile = undefined;
      });
  }

  setDataFormValues(){
    if (this.userData.user_data?.hobby) {
      this.dataForm.controls.hobby.setValue(this.userData.user_data.hobby);
    }

    if (this.userData.user_data?.personal_info) {
      this.dataForm.controls.personal_info.setValue(this.userData.user_data.personal_info);
    }

    if (this.userData.user_data?.favorite_animal) {
      this.dataForm.controls.favoriteAnimal.setValue(this.userData.user_data.favorite_animal);
    }

    if (this.userData.user_data?.favorite_color) {
      this.dataForm.controls.favoriteColor.setValue(this.userData.user_data.favorite_color);
    }

    if (this.userData.user_data?.favorite_drink) {
      this.dataForm.controls.favoriteDrink.setValue(this.userData.user_data.favorite_drink);
    }

    if (this.userData.user_data?.favorite_tree) {
      this.dataForm.controls.favoriteTree.setValue(this.userData.user_data.favorite_tree);
    }

    if (this.userData.user_data?.favorite_game) {
      this.dataForm.controls.favoriteGame.setValue(this.userData.user_data.favorite_game);
    }

    if (this.userData.user_data?.favorite_place) {
      this.dataForm.controls.favoritePlace.setValue(this.userData.user_data.favorite_place);
    }
  }

  getFile(fileName) {
    this.stepService.getFile(fileName).subscribe( data => {
      const downloadURL = window.URL.createObjectURL(data);
      saveAs(downloadURL);
    });
  }

  redirectBack(){
    this.router.navigate(['']);
  }

  navigate(url) {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
