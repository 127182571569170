// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://newcomerjourney.com:8443',

  allConfigItems: [
    {id: '1', name: 'First Steps',        icon: 'assets/steps.png',        path: 'first-steps'},
    {id: '2', name: 'Company Culture',    icon: 'assets/culture.png',      path: 'culture'},
    {id: '3', name: 'Useful Links',       icon: 'assets/links.png',        path: 'links'},
    {id: '4', name: 'Team & I',           icon: 'assets/team.png',         path: 'team'},
    {id: '5', name: 'Our topics',         icon: 'assets/quantumComp.svg',  path: 'topics'},
    {id: '6', name: 'Lets ride together', icon: 'assets/horse.svg',        path: 'lets-ride-together'}
  ],

  newUserStatus: 0
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
