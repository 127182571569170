import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StepService} from "../../../../../../services/step.service";
import {UserService} from "../../../../../../services/user.service";
import {UnitComponent} from "../../../unit/unit.component";
import {environment} from "../../../../../../../environments/environment";

const itemsConfig = environment.allConfigItems;

@Component({
  selector: 'app-unit-company-culture-award',
  templateUrl: './unit-company-culture-award.component.html',
  styleUrls: ['./unit-company-culture-award.component.scss']
})
export class UnitCompanyCultureAwardComponent extends UnitComponent implements OnInit {

  itemStepID = itemsConfig[1].id;

  constructor(router: Router, stepService: StepService, userService: UserService, route: ActivatedRoute) {
    super(userService, stepService, router, route);
  }

  ngOnInit(): void {
  }


}
