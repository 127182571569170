import {Component, Input, OnInit} from '@angular/core';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-unit-header',
  templateUrl: './unit-header.component.html',
  styleUrls: ['./unit-header.component.scss']
})
export class UnitHeaderComponent implements OnInit {

  @Input() headerText;
  @Input() headerDetail;

  constructor(public library: FaIconLibrary,
              public router: Router) {

    library.addIcons(faHome);
  }

  redirect(path: string){
    this.router.navigate([path]);
  }

  ngOnInit(): void {
  }

}
