<div class="full-screen">
  <app-admin-header></app-admin-header>

  <div class="container main-container mt-3">
    <form [formGroup]="teamForm">
      <div class="row mt-5">
        <div class="col-12">
          <h6><b>Add fun fact about member team</b></h6>
        </div>
      </div>

      <div class="row items-row">
        <div class="col-12 items-col">
          <h6>Name</h6>
          <input id="name" type="text" class="form-control" formControlName="name">
        </div>

        <div class="col-12 items-col">
          <h6>Description</h6>
          <input id="description" type="text" class="form-control" formControlName="description">
        </div>
      </div>

      <div class="row row-footer">
        <div class="col-6" align="left">
          <button id="back_page" type="button" class="btn items-button" (click)="redirect('admin-teams')">Back</button>
        </div>

        <div class="col-6" align="right">
          <button id="add_page" type="button" class="btn items-button" (click)="addFunFact()">Add fun fact</button>
        </div>
      </div>
    </form>
  </div>
</div>
