import { Component, OnInit } from '@angular/core';
import {UnitComponent} from '../unit/unit.component';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {StepService} from '../../../../services/step.service';
import {UserService} from '../../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {faCheck, faCircle, faStar} from '@fortawesome/free-solid-svg-icons';
import {faSquare} from '@fortawesome/free-regular-svg-icons';
import {environment} from '../../../../../environments/environment';

const itemsConfig = environment.allConfigItems;

@Component({
  selector: 'app-unit-lets-ride-together',
  templateUrl: './unit-lets-ride-together.component.html',
  styleUrls: ['./unit-lets-ride-together.component.scss']
})
export class UnitLetsRideTogetherComponent extends UnitComponent implements OnInit {

  itemStepID = itemsConfig[5].id;

  constructor(private library: FaIconLibrary, stepService: StepService, userService: UserService, router: Router, route: ActivatedRoute) {

    super(userService, stepService, router, route);

    library.addIcons(faStar);
    library.addIcons(faSquare);
    library.addIcons(faCheck);
    library.addIcons(faCircle);
  }

  ngOnInit(): void {
  }

}
