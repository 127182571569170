import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {UserService} from '../../../../services/user.service';
import {faQuestion, faStar, faUser, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {TeamFunFact, UserData} from '../../../../interfaces/interfaces';
import {TeamService} from '../../../../services/team.service';

@Component({
  selector: 'app-fun-fact',
  templateUrl: './fun-fact.component.html',
  styleUrls: ['./fun-fact.component.scss']
})
export class FunFactComponent implements OnInit {

  userData: UserData;
  teamFunFact: TeamFunFact;

  maxPoint = 950;
  actPoint = 0;
  actPercentagePoint = 0;

  constructor(private router: Router,
              private library: FaIconLibrary,
              private userService: UserService,
              private _teamService: TeamService) {

    library.addIcons(faUser);
    library.addIcons(faQuestion);
    library.addIcons(faWindowClose);
    library.addIcons(faStar);
  }


  ngOnInit(): void {
    this.loadUserData();
    this.subsNewUserData();
  }

  redirect(path) {
    this.router.navigate([path]);
  }

  getFunFact() {
    this._teamService.getUserTeamFunFact(this.userData.user_info.external_id).subscribe( data => {
      this.teamFunFact = data;
    });
  }

  loadUserData() {
    this.userData = this.userService.getActUserData();
    this.actPoint = this.userData?.user_points?.score;
    this.setPoint();
    this.getFunFact();
  }

  subsNewUserData() {
    this.userService.userDataActNotifier$.subscribe( (userData: UserData) => {
      this.userData = userData;
      this.actPoint = userData.user_points.score;

      this.setPoint();
    });
  }

  setPoint() {
    this.actPercentagePoint = this.actPoint / this.maxPoint * 100;
  }
}
