<div class="full-screen">
  <app-admin-header></app-admin-header>

  <div class="container main-container">

    <div class="row items-row-title-p mt-3">
      <div class="col-12">
        <h6><b>Create new team</b></h6>
        <p>Please avoid using personal data (GDPR)</p>
      </div>
    </div>

    <form [formGroup]="teamForm">
      <div class="row items-admin-row">
        <div class="col-12 items-col">
          <h6><span class="requiredInput">*</span>Name</h6>
          <input id="first_name" type="text" class="form-control" formControlName="name" placeholder="Please choose a representative name">
        </div>
        <div *ngIf="formControls.name.invalid && (formControls.name.dirty || formControls.name.touched)" class="error">
          <div *ngIf="formControls.name.errors.required">Name is required.</div>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col">
          <h6><span class="requiredInput">*</span>Description</h6>
          <input id="last_name" type="text" class="form-control" formControlName="description" placeholder="What is the role of your team">
        </div>
        <div *ngIf="formControls.description.invalid && (formControls.description.dirty || formControls.description.touched)" class="error">
          <div *ngIf="formControls.description.errors.required">Description is required.</div>
        </div>
      </div>

      <div class="row row-footer">
        <div class="col-6" align="left">
          <button id="back_page" type="button" class="btn items-button" (click)="redirect('admin-teams')">
            <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
          </button>
        </div>

        <div class="col-6" align="right">
          <button id="user_page" type="button" class="btn items-button" (click)="createTeam()" [disabled]="teamForm.invalid">Create team</button>
        </div>
      </div>

    </form>
  </div>
</div>
