<app-unit-header [headerText]="'Useful Link'" [headerDetail]="'Quicker way to find answers for frequently asked questions.'"></app-unit-header>

<div class="container main-container">
  <div class="row items-row">
    <div class="col-1 items-col">
      <img class="alert-small-image" src="./assets/items-img/alert.png" alt="image" />
    </div>
    <div class="col-11 items-col">
      <p>„Please, find useful links in „Download“ below description and send them to your new email address. The best way to browse them is from the company PC due to our high security standards.“</p>
    </div>
  </div>

<!--  <div class="row items-row-box" id="chatbotURL" tabindex="0">-->
<!--    <div class="col-3 items-col">-->
<!--      <img class="link-small-image" src="./assets/items-img/links/chatbot.svg" alt="image" />-->
<!--    </div>-->

<!--    <div class="col-9 items-col">-->
<!--      <div class="row">-->
<!--        <div class="col-12"><h5>ChatBot</h5></div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-12"><p>For all your questions</p></div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row items-row-box" id="finderURL" tabindex="0">-->
<!--    <div class="col-3 items-col">-->
<!--      <img class="link-small-image" src="./assets/items-img/links/c&RFinder.svg" alt="image" />-->
<!--    </div>-->

<!--    <div class="col-9 items-col">-->
<!--      <div class="row">-->
<!--        <div class="col-12"><h5>C&R Finder</h5></div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-12"><p>To find colleagues</p></div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row items-row-box" id="ideasURL" tabindex="0">-->
<!--    <div class="col-3 items-col">-->
<!--      <img class="link-small-image" src="./assets/items-img/links/ersteIdeas.svg" alt="image" />-->
<!--    </div>-->

<!--    <div class="col-9 items-col">-->
<!--      <div class="row">-->
<!--        <div class="col-12"><h5>Erste Ideas</h5></div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-12"><p>To share your briliant ideas</p></div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row items-row-box" id="carURL" tabindex="0">-->
<!--    <div class="col-3 items-col">-->
<!--      <img class="link-small-image" src="./assets/items-img/links/carSharing.svg" alt="image" />-->
<!--    </div>-->

<!--    <div class="col-9 items-col">-->
<!--      <div class="row">-->
<!--        <div class="col-12"><h5>CarSharing</h5></div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-12"><p>For effective business travelling</p></div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row items-row-box" id="orgaURL" tabindex="0">-->
<!--    <div class="col-3 items-col">-->
<!--      <img class="link-small-image" src="./assets/items-img/links/orgStructure.svg" alt="image" />-->
<!--    </div>-->

<!--    <div class="col-9 items-col">-->
<!--      <div class="row">-->
<!--        <div class="col-12"><h5>Orga Structure</h5></div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-12"><p>Just FYI</p></div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row items-row-box" id="networkURL" tabindex="0">-->
<!--    <div class="col-3 items-col">-->
<!--      <img class="link-small-image" src="./assets/items-img/links/networking.svg" alt="image" />-->
<!--    </div>-->

<!--    <div class="col-9 items-col">-->
<!--      <div class="row">-->
<!--        <div class="col-12"><h5>Open Network</h5></div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-12"><p>Our Intranet</p></div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row items-row-box" id="benefits" tabindex="0">-->
<!--    <div class="col-3 items-col">-->
<!--      <img class="link-small-image" src="./assets/items-img/links/Benefits.png" alt="image" />-->
<!--    </div>-->

<!--    <div class="col-9 items-col">-->
<!--      <div class="row">-->
<!--        <div class="col-12"><h5>Benefits</h5></div>-->
<!--      </div>-->

<!--      <div class="row">-->
<!--        <div class="col-12"><p>Our benefits</p></div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="row items-row-box" id="benefits" tabindex="0">
    <div class="col-12 items-col">
     <h2>Chatbot</h2>
      <p>For all your questions about the company. </p>
      <p><fa-icon (click)="openNewPage(chatBotUrl)" class="icon" [icon]="['fas','link']"></fa-icon>Link</p>
    </div>

    <div class="col-12 items-col">
      <img class="link-small-image" src="./assets/items-img/links-img/1.png" alt="image" />
    </div>
  </div>

  <div class="row items-row-box" id="finder" tabindex="0">
    <div class="col-12 items-col">
      <h2 class="m-1">C&R Finder</h2>
      <p>To find colleagues based on skills and responsibilities.</p>
      <p><fa-icon (click)="openNewPage(finderUrl)" class="icon" [icon]="['fas','link']"></fa-icon>Link</p>
    </div>

    <div class="col-12 items-col">
      <img class="link-small-image" src="./assets/items-img/links-img/2.png" alt="image" />
    </div>
  </div>

  <div class="row items-row-box" id="ideas" tabindex="0">
    <div class="col-12 items-col">
      <h2 class="m-1">Erste Ideas</h2>
      <p>To share your briliant ideas with us and be inspired by others.</p>
      <p><fa-icon (click)="openNewPage(ideasUrl)" class="icon" [icon]="['fas','link']"></fa-icon>Link</p>
    </div>

    <div class="col-12 items-col">
      <img class="link-small-image" src="./assets/items-img/links-img/3.png" alt="image" />
    </div>
  </div>

  <div class="row items-row-box" id="orga" tabindex="0">
    <div class="col-12 items-col">
      <h2 class="m-1">OrgaStructure</h2>
      <p>Just FYI.</p>
      <p><fa-icon (click)="openNewPage(orgUrl)" class="icon" [icon]="['fas','link']"></fa-icon>Link</p>
    </div>

    <div class="col-12 items-col">
      <img class="link-small-image" src="./assets/items-img/links-img/5.png" alt="image" />
    </div>
  </div>

  <div class="row items-row-box" id="network" tabindex="0">
    <div class="col-12 items-col">
      <h2 class="m-1">Open Network </h2>
      <p>Our Intranet.</p>
      <p><fa-icon (click)="openNewPage(networkUrl)" class="icon" [icon]="['fas','link']"></fa-icon>Link</p>
    </div>

    <div class="col-12 items-col">
      <img class="link-small-image" src="./assets/items-img/links-img/6.png" alt="image" />
    </div>
  </div>

  <div class="row items-row-box" id="car" tabindex="0">
    <div class="col-12 items-col">
      <h2 class="m-1">CarSharing</h2>
      <p>For effective business trip travelling.</p>
      <p><fa-icon (click)="openNewPage(carUrl)" class="icon" [icon]="['fas','link']"></fa-icon>Link</p>
    </div>

    <div class="col-12 items-col">
      <img class="link-small-image" src="./assets/items-img/links-img/4.png" alt="image" />
    </div>
  </div>

  <div class="row row-footer">
    <div class="col-6" align="left">
      <button id="back_page" type="button" class="btn items-button" (click)="redirect('')">Back</button>
    </div>

    <div class="col-6" align="right">
      <button id="next_page" type="button" class="btn items-button" (click)="downloadLinkFile()">Download</button>
    </div>
  </div>
</div>
