<div class="full-screen">
  <app-admin-header></app-admin-header>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdropRemoveAction" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="align-items: flex-end;">
      <div class="modal-content">
        <div class="modal-body">
          <p class="secondary-color-test">Do you want to delete selected scheduled action?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn items-button" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn items-button" data-bs-dismiss="modal" (click)="deleteScheduledAction()">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <div class="container main-container">

    <div class="row items-row mt-2">
      <div class="col-12">
        <h5><b>All user actions scheduled</b></h5>
      </div>
    </div>

    <div class="row group-menu-item"  *ngFor="let action of actionsScheduled">
      <div class="col-12">
        <div class="row">
          <div class="col-10">
            <h4>{{action.name}}</h4>
          </div>
          <div class="col-2"></div>
        </div>

        <div class="row">
          <div class="col-10">
            <p>
              {{action.description}}
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-10">
            <p>
              <b>{{action.date | date}}</b>
              <fa-icon *ngIf="action.status == 0" class="icon"[icon]="['fas','check']"></fa-icon>
              <fa-icon *ngIf="action.status == 1" class="icon"[icon]="['fas','clock']"></fa-icon>
            </p>
          </div>
          <div class="col-2" align="right">
            <fa-icon data-bs-toggle="modal" (click)="setSelectedAction(action.id)" data-bs-target="#staticBackdropRemoveAction" class="icon-trash" [icon]="['fas', 'trash']"></fa-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12"></div>
    </div>

    <div class="row row-footer">
      <div class="col-6" align="left">
        <button id="back_page" type="button" class="btn items-button" (click)="redirectBack()">
          <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
        </button>
      </div>

      <div class="col-6" align="right">
        <button id="actions_page" type="button" class="btn items-button" [routerLink]="['/admin-user-add-action', user_external_id]">Schedule action</button>
      </div>
    </div>
  </div>
</div>
