<div class="full-screen">
  <app-admin-header></app-admin-header>

  <div class="container main-container mt-3">
      <form [formGroup]="teamMember">

        <div class="row items-row-title-p mt-3">
          <div class="col-12">
            <h6><b>Create team member</b></h6>
            <p>Please avoid using personal data (GDPR)</p>
          </div>
        </div>

        <div class="row items-admin-row">
          <div class="col-12 items-col">
            <h6><span class="requiredInput">*</span>Name</h6>
            <input id="member_name" type="text" class="form-control" formControlName="member_name" placeholder="First name only">
          </div>
          <div *ngIf="formControls.member_name.invalid && (formControls.member_name.dirty || formControls.member_name.touched)" class="error">
            <div *ngIf="formControls.member_name.errors.required">Name is required.</div>
          </div>
        </div>

        <div class="row items-admin-row">
          <div class="col-12 items-col">
            <h6><span class="requiredInput">*</span>Position</h6>
            <input id="member_position" type="text" class="form-control" formControlName="member_position" placeholder="Job title">
          </div>
          <div *ngIf="formControls.member_position.invalid && (formControls.member_position.dirty || formControls.member_position.touched)" class="error">
            <div *ngIf="formControls.member_position.errors.required">Position is required.</div>
          </div>
        </div>

        <div class="row items-admin-row">
          <div class="col-12 items-col">
            <h6><span class="requiredInput">*</span>Short bio</h6>
            <input id="member_description" type="text" class="form-control" formControlName="member_description" placeholder="Include hobbies, intersts etc.">
          </div>
          <div *ngIf="formControls.member_description.invalid && (formControls.member_description.dirty || formControls.member_description.touched)" class="error">
            <div *ngIf="formControls.member_description.errors.required">Short bio is required.</div>
          </div>
        </div>

        <div class="row items-admin-row">
          <div class="col-12 items-col">
            <h6>Url</h6>
            <input id="member_url" type="text" class="form-control" formControlName="member_url" placeholder="LinkedIn profile URL">
          </div>
        </div>

        <div class="row items-admin-row">
          <div class="col-12 items-col">
            <h6>Image</h6>
            <input class="form-control" type="file" id="formImage" (change)="selectFile($event)">
          </div>
          <div id="textInfo" class="form-text">
            Please ask before sharing personal image, if no photo is uploaded, default picture is used.
          </div>
        </div>

        <div class="row row-footer">
          <div class="col-6" align="left">
            <button id="back_page" type="button" class="btn items-button" (click)="redirect()">
              <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
            </button>
          </div>

          <div class="col-6" align="right">
            <button id="add_page" type="button" class="btn items-button" (click)="addTeamMember()" [disabled]="teamMember.invalid">Add member</button>
          </div>
        </div>
      </form>
  </div>
</div>
