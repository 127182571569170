import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faCircle, faLink} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../../../environments/environment';
import {UnitComponent} from '../unit/unit.component';
import {UserService} from '../../../../services/user.service';
import {StepService} from '../../../../services/step.service';

const itemsConfig = environment.allConfigItems;

@Component({
  selector: 'app-unit-useful-links',
  templateUrl: './unit-useful-links.component.html',
  styleUrls: ['./unit-useful-links.component.scss']
})
export class UnitUsefulLinksComponent extends UnitComponent implements OnInit {

  itemStepID = itemsConfig[2].id;

  chatBotUrl = 'https://teams.microsoft.com/l/app/57726d0b-e386-401d-83b9-802bcff843e6?source=app-details-dialog';
  finderUrl = 'https://ersteideas.ersteapps.com/crfindersearch';
  ideasUrl = 'https://ersteideas.ersteapps.com/ideas';
  orgUrl = 'https://intranet.ersteopen.net/Portal.Node/groups/About_us/SF_MEIN_BEREICH/Erste_Digital_OrgChart/start_Management_Structure.en.html';
  networkUrl = 'https://intranet.ersteopen.net/Portal.Node/portal';
  carUrl = 'https://erstegroup.sharepoint.com/sites/EDHR/SitePages/Easy,-sustainable-and-cost-effective-traveling.aspx';

  constructor(private library: FaIconLibrary, stepService: StepService, router: Router, userService: UserService, route: ActivatedRoute) {

    super(userService, stepService, router, route);

    library.addIcons(faCircle);
    library.addIcons(faLink);
  }

  ngOnInit(): void {
    this.setStepComplete(false, null);
  }

  downloadLinkFile() {
      const link = document.createElement('a');
      link.download = 'usefulLinks';
      link.href = '/assets/UsefulLinks_A4.pdf';
      link.click();
  }
}
