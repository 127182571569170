<div class="full-screen">

  <div class="header">
    <div class="container">
      <div class="row items-row-narrow-title">
        <app-header></app-header>
      </div>
    </div>
  </div>

  <div class="main-screen">

    <div class="container">
      <div class="row items-row-narrow-no">
        <div class="col-12 items-col-narrow-no-img" align="center">
          <img class="" src="./../../../../assets/welcome.png" alt="image" />
        </div>
      </div>

      <div class="row items-row-narrow-title">
        <div class="col-12 items-col text-center">
          <h1>Invalid URL!</h1>
        </div>
      </div>

      <div class="row items-row-narrow">
        <div class="col-12 items-col-narrow-no-img text-center">
          <p>Please scan the code that was delivered to you</p>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">

  </div>
</div>




