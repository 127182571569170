import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {UserService} from "../../services/user.service";
import {Step, UserData} from "../../interfaces/interfaces";
import {faLock, faLockOpen, faQuestion, faUser} from '@fortawesome/free-solid-svg-icons';
import {StepService} from "../../services/step.service";
import { saveAs } from "file-saver";

@Component({
  selector: 'app-main-gifts',
  templateUrl: './main-gifts.component.html',
  styleUrls: ['./main-gifts.component.scss']
})
export class MainGiftsComponent implements OnInit {

  userData: UserData;
  actPoint: number = 0;

  constructor(private router: Router,
              private library: FaIconLibrary,
              private stepService: StepService,
              private userService: UserService) {

    library.addIcons(faUser);
    library.addIcons(faQuestion);
    library.addIcons(faLock);
    library.addIcons(faLockOpen);
  }

  ngOnInit(): void {
    this.loadUserData();
    this.subsNewUserData();
  }

  redirect(path) {
    this.router.navigate([path]);
  }

  loadUserData() {
    this.userData = this.userService.getActUserData();
    this.actPoint = this.userData?.user_points?.score;
  }

  subsNewUserData() {
    this.userService.userDataActNotifier$.subscribe( (userData: UserData) => {
      this.userData = userData;
      this.actPoint = userData.user_points.score;
    });
  }

  getUserCertificate() {
    this.stepService.getUserCertificate(this.userData.user_info.external_id).subscribe( data => {
      const downloadURL = window.URL.createObjectURL(data);
      saveAs(downloadURL);
    });
  }

  downloadLinkFile() {
    const link = document.createElement('a');
    link.download = 'Certificate';
    link.href = '/assets/CertificateTree.png';
    link.click();
  }
}
