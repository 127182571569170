import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Team, TeamData, TeamFunFact} from '../interfaces/interfaces';
import {environment} from '../../environments/environment';

const URL = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(public http: HttpClient) { }

  getUserTeamById(user_id): Observable<TeamData>{
    return this.http.get<TeamData>(URL + '/api/team/' + user_id, this.getHttpOptions());
  }

  getTeams(): Observable<Team[]>{
    return this.http.get<Team[]>(URL + '/api/admin/teams', this.getHttpOptions());
  }

  getTeamById(id): Observable<TeamData>{
    return this.http.get<TeamData>(URL + '/api/admin/team/' + id, this.getHttpOptions());
  }

  getUserTeamFunFact(user_id): Observable<TeamFunFact>{
    return this.http.get<TeamFunFact>(URL + '/api/team/fun-fact/' + user_id, this.getHttpOptions());
  }

  getTeamFunFact(team_id: string): Observable<TeamFunFact>{
    return this.http.get<TeamFunFact>(URL + '/api/admin/team/fun-fact/' + team_id, this.getHttpOptions());
  }

  createNewTeam(formData: FormData) {
    return this.http.post(URL + '/api/admin/team/create-team', formData);
  }

  createNewMember(formData: FormData) {
    return this.http.post(URL + '/api/admin-upl/team/member/add-member', formData);
  }

  createTeamFunFact(formData: FormData) {
    return this.http.post(URL + '/api/admin/team/fun-fact', formData);
  }

  updateTeam(formData: FormData, team_id: string) {
    return this.http.put(URL + '/api/admin/team/update-team/' + team_id, formData);
  }

  deleteTeam(team_id: string) {
    return this.http.delete(URL + '/api/admin/team/' + team_id, this.getHttpOptions());
  }

  deleteTeamMember(member_id: string) {
    return this.http.delete(URL + '/api/admin/team/member/' + member_id, this.getHttpOptions());
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '
        }
      )};
  }
}
