<div class="main-container">
  <div class="container navbar-header">
    <div class="row">
      <div class="col-12">
        <h4><fa-icon class="icon" [icon]="['fas','user']"></fa-icon>{{userData?.user_info?.first_name}} {{userData?.user_info?.last_name}}</h4>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-2">
        <img class="progress-image" src="assets/point.png" />
      </div>

      <div class="col-5">
        <div class="progress" style="height: 7px;">
          <div class="progress-bar progress-header" role="progressbar" [style.width]="actPercentagePoint +'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>

      <div class="col-5 progress-point" align="right">
        {{actPoint}} / {{maxPoint}} <fa-icon class="icon-header" [icon]="['fas','star']"></fa-icon>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row items-row-awards">
      <div class="col-12 items-col" align="center">

        <h4>Do you want to know one fun thing about your team member?</h4>

        <img class="item-award" src="./assets/items-img/secret.png" alt="image" />

        <p><b>{{teamFunFact.name}}</b></p>

        <p>{{teamFunFact.description}}</p>
      </div>
    </div>

    <div class="row row-footer">
      <div class="col-12" align="left">
        <button id="back_page" type="button" class="btn items-button" (click)="redirect('')">Back</button>
      </div>
    </div>

  </div>
</div>




