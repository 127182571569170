<div class="full-screen">
  <app-admin-header></app-admin-header>

  <div class="container main-container">
    <div class="row group-menu-item" *ngFor="let item of menuList" (click)="redirect(item.path)">
      <div class="col-10">
        <h4><b>{{item.name}}</b></h4>
      </div>

      <div class="col-2">
        <h4><fa-icon class="icon" [icon]="['fas',item.iconName]"></fa-icon></h4>
      </div>
    </div>

    <div class="row items-row">
      <div class="col-12">
        <h6><b>To create a new user</b></h6>
        <ul>
          <li>Create a team if you have not done it yet</li>
          <li>Create a user for the newcomer (By HR)</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12"></div>
    </div>
  </div>
</div>
