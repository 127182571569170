import { Component, OnInit } from '@angular/core';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {Router} from '@angular/router';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {faDotCircle} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-how-to-use',
  templateUrl: './how-to-use.component.html',
  styleUrls: ['./how-to-use.component.scss']
})
export class HowToUseComponent implements OnInit {

  constructor(private library: FaIconLibrary,
              private router: Router) {

    library.addIcons(faCircle);
    library.addIcons(faDotCircle);
  }

  ngOnInit(): void {
  }

  redirect(path: string) {
    this.router.navigate([path]);
  }

}
