import { Component, OnInit } from '@angular/core';
import {TeamService} from '../../../../../../../services/team.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {UserData} from '../../../../../../../interfaces/interfaces';
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-new-team-member',
  templateUrl: './new-team-member.component.html',
  styleUrls: ['./new-team-member.component.scss']
})
export class NewTeamMemberComponent implements OnInit {

  teamMember: FormGroup;
  currentFile: File;

  constructor(private _teamService: TeamService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _formBuilder: FormBuilder,
              private _library: FaIconLibrary) {

    _library.addIcons(faArrowCircleLeft);
  }

  ngOnInit(): void {
    this.teamMember = this._formBuilder.group({
      member_name: ['', [Validators.required]],
      member_position: ['', [Validators.required]],
      member_description: ['', [Validators.required]],
      member_url: ['', []],
    });
  }

  addTeamMember() {
    const formData: FormData = new FormData();

    if (this.teamMember.invalid) {
      return;
    }

    formData.append('team_id', this._route.snapshot.params.id);
    formData.append('name', this.teamMember.controls.member_name.value);
    formData.append('position', this.teamMember.controls.member_position.value);
    formData.append('description', this.teamMember.controls.member_description.value);
    formData.append('url', this.teamMember.controls.member_url.value);
    formData.append('image', this.currentFile);

    this._teamService.createNewMember(formData).subscribe((data: UserData) => {
        this._router.navigate(['/admin-team', this._route.snapshot.params.id]);
    }, error => {
        this.currentFile = undefined;
      }
    );
  }

  selectFile(event): void {
    this.currentFile = event.target.files[0];
  }

  redirect() {
    this._router.navigate(['/admin-team', this._route.snapshot.params.id]);
  }

  get formControls() {
    return this.teamMember.controls;
  }

}
