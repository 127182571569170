import { Component, OnInit } from '@angular/core';
import {UnitComponent} from '../unit/unit.component';
import {StepService} from '../../../../services/step.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../services/user.service';
import {environment} from '../../../../../environments/environment';

const itemsConfig = environment.allConfigItems;

@Component({
  selector: 'app-unit-our-topics',
  templateUrl: './unit-our-topics.component.html',
  styleUrls: ['./unit-our-topics.component.scss']
})
export class UnitOurTopicsComponent extends UnitComponent implements OnInit {

  itemStepID = itemsConfig[4].id;

  constructor(stepService: StepService, router: Router, userService: UserService, route: ActivatedRoute) {

    super(userService, stepService, router, route);
  }

  ngOnInit(): void {
  }

}
