import { Injectable } from '@angular/core';
import {UserData} from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  setUserData(data) {
    this.saveSessionStorageData('userData', data.data);
    this.saveSessionStorageData('userKey', data.accessToken);
  }

  setUserAccessToken(data) {
    this.saveSessionStorageData('userKey', data);
  }

  getActUser(): UserData {
    return this.getSessionStorageData('userData');
  }

  setUserInfo(data: UserData) {
    this.saveSessionStorageData('userData', data);
  }

  getToken() {
    return this.getSessionStorageData('userKey');
  }

  isAuthenticated(): boolean {
    const token = this.getSessionStorageData('userKey');
    return !!token;
  }

  private getSessionStorageData(name){
    const data = window.sessionStorage.getItem(name);
    if (data) {
      return JSON.parse(data);
    }

    return null;
  }

  private saveSessionStorageData(name, data){
    window.sessionStorage.removeItem(name);
    window.sessionStorage.setItem(name, JSON.stringify(data));
  }
}
