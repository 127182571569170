import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

import {faCheck, faStar, faCircle} from '@fortawesome/free-solid-svg-icons';
import {faSquare} from '@fortawesome/free-regular-svg-icons';
import {StepService} from "../../../../services/step.service";
import {UserService} from "../../../../services/user.service";
import {environment} from "../../../../../environments/environment";
import {UnitComponent} from "../unit/unit.component";

const itemsConfig = environment.allConfigItems;

@Component({
  selector: 'app-unit-first-steps',
  templateUrl: './unit-first-steps.component.html',
  styleUrls: ['./unit-first-steps.component.scss']
})
export class UnitFirstStepsComponent extends UnitComponent implements OnInit {

  itemStepID = itemsConfig[0].id;

  constructor(private library: FaIconLibrary, stepService: StepService, userService: UserService, router: Router, route: ActivatedRoute) {

    super(userService, stepService, router, route);

    library.addIcons(faStar);
    library.addIcons(faSquare);
    library.addIcons(faCheck);
    library.addIcons(faCircle);
  }

  ngOnInit(): void {
  }
}
