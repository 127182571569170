<div class="container navbar-header-items">
  <div class="row">
    <div class="col-12">
      <h2>{{headerText}}</h2>
    </div>

  </div>

  <div class="row align-items-end mt-2">
    <div class="col-10">
      <p>{{headerDetail}}</p>
    </div>

    <div class="col-2 icon-action">
      <fa-icon [icon]="['fas','home']" size="2x" (click)="redirect('')"></fa-icon>
    </div>
  </div>

</div>
