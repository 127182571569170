import { Component, OnInit } from '@angular/core';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {faDotCircle} from '@fortawesome/free-regular-svg-icons';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {environment} from '../../../../environments/environment';

const newUserLoginStatus = environment.newUserStatus;

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {

  constructor(private library: FaIconLibrary,
              private userService: UserService,
              private router: Router) {

    library.addIcons(faCircle);
    library.addIcons(faDotCircle);
  }

  ngOnInit(): void {
  }

  redirect(path: string) {
    const data = this.userService.getActUserData()?.user_info;

    if (data && data.status === newUserLoginStatus) {
      this.userService.setFirstUserAccessComplete().subscribe();
    }

    this.router.navigate([path]);
  }
}
