import { Component, OnInit } from '@angular/core';
import {Location, Team, UserData, UserPosition} from '../../../../../../../interfaces/interfaces';
import {ActivatedRoute, Router} from '@angular/router';
import {TeamService} from '../../../../../../../services/team.service';
import {UserService} from '../../../../../../../services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  userForm: FormGroup;
  userData: UserData;
  teams: Team[] = [];
  userPositions: UserPosition[] = [];
  locations: Location[] = [];

  constructor(private _router: Router,
              private _teamService: TeamService,
              private _userService: UserService,
              private _route: ActivatedRoute,
              private _library: FaIconLibrary,
              private _formBuilder: FormBuilder) {

    _library.addIcons(faArrowCircleLeft);
  }

  ngOnInit(): void {
    this.userForm = this._formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      manager_email: ['', [Validators.required, Validators.email]],
      team: ['', [Validators.required]],
      position: ['', [Validators.required]],
      coupon_url: ['', []],
      first_day: ['', [Validators.required]],
      emp_type: ['', [Validators.required]],
      location: ['', [Validators.required]],
    });

    this._userService.getAllLocations().subscribe( data => {
      this.locations = data;
    });

    this._teamService.getTeams().subscribe( data => {
      if (data) {
        this.teams = data;
        this.getUserPositions();
      }
    });
  }

  getUserPositions() {
    this._userService.getAllUserPositions().subscribe( positions => {
      this.userPositions = positions;
      this.getUserData();
    });
  }

  getUserData() {
    this._userService.getUserData(this._route.snapshot.params.id).subscribe( data => {
      if (data) {
        this.userData = data;
        this.setDataFormDefaultValues();
      }
    });
  }

  saveUser() {
    const formData: FormData = new FormData();

    if (this.userForm.invalid) {
      return;
    }

    formData.append('external_id', this.userData.user_info.external_id);
    formData.append('first_name', this.userForm.controls.first_name.value);
    formData.append('last_name', this.userForm.controls.last_name.value);
    formData.append('email', this.userForm.controls.email.value);
    formData.append('manager_email', this.userForm.controls.manager_email.value);
    formData.append('role', 'USER');
    formData.append('team_id', this.userForm.controls.team.value);
    formData.append('position_id', this.userForm.controls.position.value);
    formData.append('coupon_url', this.userForm.controls.coupon_url.value);
    formData.append('first_day', this.userForm.controls.first_day.value);
    formData.append('location', this.userForm.controls.location.value);
    formData.append('emp_type', this.userForm.controls.emp_type.value);

    this._userService.updateUser(formData).subscribe((data: UserData) => {
      this._router.navigate(['/admin-user', this._route.snapshot.params.id]);
    });
  }

  setDataFormDefaultValues() {
    this.userForm.controls.first_name.setValue(this.userData.user_info.first_name);
    this.userForm.controls.last_name.setValue(this.userData.user_info.last_name);
    this.userForm.controls.email.setValue(this.userData.user_info.email);
    this.userForm.controls.manager_email.setValue(this.userData.user_info.manager_email);
    this.userForm.controls.team.setValue(this.userData.user_info.team);
    this.userForm.controls.position.setValue(this.userData.user_info.position_id);
    this.userForm.controls.coupon_url.setValue(this.userData.user_info.coupon_url);
    this.userForm.controls.first_day.setValue(this.userData.user_info.first_day_in_work);
    this.userForm.controls.location.setValue(this.userData.user_info.location);
    this.userForm.controls.emp_type.setValue(this.userData.user_info.emp_type);
  }

  redirect() {
    this._router.navigate(['/admin-user', this._route.snapshot.params.id]);
  }

  get formControls() {
    return this.userForm.controls;
  }
}
