import { Component, OnInit } from '@angular/core';
import {TeamData, TeamFunFact, UserData} from '../../../../../../../interfaces/interfaces';
import {TeamService} from '../../../../../../../services/team.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../../../../../environments/environment';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faArrowCircleLeft, faBars, faTrash} from '@fortawesome/free-solid-svg-icons';

const URL = environment.apiEndpoint;

@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss']
})
export class TeamDetailComponent implements OnInit {

  teamData: TeamData;
  teamFunFact: TeamFunFact;
  actURL: string = URL;
  editedMode = false;

  teamForm: FormGroup;

  constructor(private _teamService: TeamService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _formBuilder: FormBuilder,
              private _library: FaIconLibrary) {

    _library.addIcons(faLinkedin);
    _library.addIcons(faTrash);
    _library.addIcons(faBars);
    _library.addIcons(faArrowCircleLeft);
  }

  ngOnInit(): void {
    this.teamForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      teamFunFact_name: ['', []],
      teamFunFact_desc: ['', []],
    });

    this.getTeamData();
  }

  updateTeam() {
    const formData: FormData = new FormData();

    if (this.teamForm.invalid) {
      return;
    }

    formData.append('name', this.teamForm.controls.name.value);
    formData.append('description', this.teamForm.controls.description.value);

    formData.append('teamFunFact_name', this.teamForm.controls.teamFunFact_name.value);
    formData.append('teamFunFact_desc', this.teamForm.controls.teamFunFact_desc.value);

    this._teamService.updateTeam(formData, this.teamData.team.id).subscribe((data: UserData) => {
      this.redirect('admin-teams');
    });
  }

  getTeamData() {
    this._teamService.getTeamById(this._route.snapshot.params.id).subscribe( data => {
      if (data) {
        this.teamData = data;
        this.getFunFact();
      }
    });
  }

  getFunFact() {
    this._teamService.getTeamFunFact(this.teamData.team.id).subscribe( data => {
      this.teamFunFact = data;
      this.setDataFormValues();
    });
  }

  deleteTeam() {
    this._teamService.deleteTeam(this.teamData.team.id).subscribe( resp => {
      this.redirect('admin-teams');
    });
  }

  deleteTeamMember(teamMemberId) {
    this._teamService.deleteTeamMember(teamMemberId).subscribe( resp => {
      this.teamData.members = this.teamData.members.filter(member => member.id != teamMemberId);
    });
  }

  setDataFormValues() {
    if (this.teamData.team.name) {
      this.teamForm.controls.name.setValue(this.teamData.team.name);
    }

    if (this.teamData.team.description) {
      this.teamForm.controls.description.setValue(this.teamData.team.description);
    }

    if (this.teamFunFact && this.teamFunFact.name) {
      this.teamForm.controls.teamFunFact_name.setValue(this.teamFunFact.name);
    }

    if (this.teamFunFact && this.teamFunFact.description) {
      this.teamForm.controls.teamFunFact_desc.setValue(this.teamFunFact.description);
    }
  }

  editMode() {
    this.editedMode = !this.editedMode;
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

  get formControls() {
    return this.teamForm.controls;
  }

}
