import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {UserInfo} from '../../interfaces/interfaces';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user_name: string;
  userNotFound = false;

  constructor(private _userService: UserService,
              private _authService: AuthService,
              private _router: Router) { }

  ngOnInit(): void {
    this.user_name = this._userService.getActUserName();
  }

  submit() {
    this.userNotFound = false;

    this._userService.loginUser(this.user_name, null).subscribe((resp: UserInfo) => {
      this._userService.saveActUserInfo(resp);
      this._authService.setUserData(resp);

      if (resp.data.user_info.status === 0) {
        this._router.navigate(['welcome']);
      } else {
        this._router.navigate(['']);
      }
    }, (err) => {
      this.userNotFound = true;
    });
  }
}
