<div class="container navbar-header" (click)="redirect('gifts')">
  <div class="row">
    <div class="col-10">
      <h4><fa-icon class="icon" [icon]="['fas','user']"></fa-icon>{{userData?.user_info?.first_name}} {{userData?.user_info?.last_name}}</h4>
    </div>

    <div class="col-2 icon-action">
      <fa-icon (click)="redirect('welcome')" class="icon" style="font-size:1.7em" [icon]="['fas','question-circle']"></fa-icon>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-2">
      <img class="progress-image" src="assets/point.png" />
    </div>

    <div class="col-5">
      <div class="progress" style="height: 7px;">
        <div class="progress-bar progress-header" role="progressbar" [style.width]="actPercentagePoint +'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>

    <div class="col-5 progress-point" align="right">
      {{actPoint}} / {{maxPoint}} <fa-icon class="icon-header" [icon]="['fas','star']"></fa-icon>
    </div>
  </div>
</div>
