import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Step, User, UserStep} from "../interfaces/interfaces";
import {environment} from "../../environments/environment";


const URL = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})
export class StepService {

  constructor(private http: HttpClient) { }

  getUserSteps(user_id): Observable<UserStep[]>{
    return this.http.get<UserStep[]>(URL + '/api/user-steps/' + user_id, this.getHttpOptions());
  }

  setStepComplete(step_id: string) {
    return this.http.post(URL + '/api/user-steps/complete-step', JSON.stringify({external_step_id: step_id}), this.getHttpOptions());
  }

  setUserPersonalInfo(formData: FormData) {
    return this.http.post(URL + '/api/user-steps/set-personal-info', formData);
  }

  getUserCertificate(user_id) {
    return this.http.get(URL + '/api/user-steps/certificate/' + user_id, {responseType: 'blob'});
  }

  getFile(fileName){
    return this.http.get(URL + '/api/user/files/' + fileName, { responseType: 'blob'});
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    };
  }

  getHttpOptionsForm() {
    return {
      headers: new HttpHeaders()
    };
  }
}
