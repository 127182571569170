<app-unit-header [headerText]="'Let’s ride together'" [headerDetail]="'This is the last step of your onboarding!'"></app-unit-header>

<div class="container main-container">

  <div class="row items-row">
    <div class="col-12 items-sub" align="center">
      <img src="./assets/items-img/letsGO/congrats.png" alt="image" />
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <p>Thanks for joining us, we are really glad to have you on board!</p>
      <p>Now you know the company and roughly understand our culture.</p>
      <p>Hope you checked VR premises and know how many nationalities we have.</p>
      <p>You e-met your mates and shared something about you as well.</p>
      <p>And you have some useful links to check once you have an access. IT will surely help you 😉</p>
      <p>Now it is time to start! If you still want to know more e.g. how to get to the office efficiently and where to find HR, visit also our Company’s overview</p>
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-sub-col" align="center">
      <img class="img-sub" src="./assets/items-img/letsGO/msg.png" alt="image" />
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <p>Thanks you did this onboarding and as we are still learning, can you give some feedback to our Inno guys to make IT matter (and better) for other colleagues?</p>
    </div>
  </div>

  <div class="row row-footer">
    <div class="col-4" align="left">
      <button id="back_page" type="button" class="btn items-button" (click)="redirect('')">Back</button>
    </div>

    <div class="col-8" align="right">
      <button id="next_page" type="button" class="btn items-button-long" (click)="mailTo(); setStepComplete(true, '')">Company overview </button>
    </div>
  </div>
</div>
