<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="9"
  [test_question]="'9. Choose countries where we have a branch: '"
  [test_img_src_1]="'./assets/items-img/culture_test_time/Countries.png'"
  [test_img_src_1_correct_anw]="1"
  [test_img_src_2]="'./assets/items-img/culture_test_time/Countries_wrong.png'"
  [test_img_src_2_correct_anw]="0"
  [test_success_redirect]="'nationalities'"
></app-unit-company-culture-test>
