import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Actions, Location, User, UserActionScheduled, UserData, UserInfo, UserPosition} from '../interfaces/interfaces';
import {environment} from '../../environments/environment';

const URL = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userData: UserData;

  private userDataAct = new Subject<UserData>();
  userDataActNotifier$ = this.userDataAct.asObservable();

  constructor(public http: HttpClient) { }

  loginUser(user_name, user_password) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(URL + '/api/user/auth/signin', JSON.stringify({username: user_name, password: user_password}), this.getHttpOptions());
  }

  getActUser(): Observable<UserData>{
    return this.http.get<UserData>(URL + '/api/user', this.getHttpOptions());
  }

  getActions(): Observable<Actions[]>{
    return this.http.get<Actions[]>(URL + '/api/admin/actions', this.getHttpOptions());
  }

  deleteAction(action_id) {
    return this.http.delete(URL + '/api/admin/user/action/' + action_id, this.getHttpOptions());
  }

  addUserAction(formData: FormData) {
    return this.http.post(URL + '/api/admin/user/action-schedule/add-action', formData);
  }

  getAllUserActionsScheduled(user_external_id): Observable<UserActionScheduled[]>{
    return this.http.get<UserActionScheduled[]>(URL + '/api/admin/actions-scheduled/' + user_external_id, this.getHttpOptions());
  }

  getUserByName(name): Observable<User>{
    return this.http.get<User>(URL + '/api/user/' + name, this.getHttpOptions());
  }

  getUserData(external_id): Observable<UserData>{
    return this.http.get<UserData>(URL + '/api/admin/user/' + external_id, this.getHttpOptions());
  }

  getAllUserPositions(): Observable<UserPosition[]>{
    return this.http.get<UserPosition[]>(URL + '/api/admin/user-positions', this.getHttpOptions());
  }

  getAllLocations(): Observable<Location[]>{
    return this.http.get<Location[]>(URL + '/api/location', this.getHttpOptions());
  }

  getAllUsers(): Observable<User[]>{
    return this.http.get<User[]>(URL + '/api/admin/users', this.getHttpOptions());
  }

  setFirstUserAccessComplete() {
    return this.http.post(URL + '/api/user/set-first-access', this.getHttpOptions());
  }

  createNewUser(formData: FormData) {
    return this.http.post(URL + '/api/admin/user/create-user', formData);
  }

  createHardware(formData: FormData) {
    return this.http.post(URL + '/api/user/set-hardware', formData);
  }

  updateUser(formData: FormData) {
    return this.http.post(URL + '/api/admin/user/update-user', formData);
  }

  deleteUser(user_id: string) {
    return this.http.delete(URL + '/api/admin/user/' + user_id, this.getHttpOptions());
  }

  resetUser(user_id: string) {
    return this.http.put(URL + '/api/admin/user/reset-user/' + user_id, this.getHttpOptions());
  }

  getUserStepStatus(step_id) {
    if (this.userData && this.userData.user_steps) {
      return this.userData.user_steps.find(step => step.step_id == step_id).step_status;
    }

    return 0;
  }

  saveActUserInfo(userData: UserInfo) {
    this.saveActUser(userData.data);
  }

  saveActUser(userData: UserData) {
    this.saveLocalStorageUserData(userData.user_info.last_name);
    this.userData = userData;
    this.userDataAct.next(userData);
  }

  getActUserData() {
    if (!this.userData) {
      const data = window.sessionStorage.getItem('userData');
      if (data) {
        return JSON.parse(data);
      }

      return null;
    }

    return this.userData;
  }

  getActUserName() {
    return this.getLocalStorageUserData();
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }
      )};
  }

  private getLocalStorageUserData(){
    return JSON.parse(localStorage.getItem('NewComerUser'));
  }

  private saveLocalStorageUserData(data){
    localStorage.setItem('NewComerUser', JSON.stringify(data));
  }
}
