import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {UserService} from '../../../../services/user.service';
import {faQuestion, faStar, faUser, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {UserData} from '../../../../interfaces/interfaces';

@Component({
  selector: 'app-beer-gift-detail',
  templateUrl: './beer-gift-detail.component.html',
  styleUrls: ['./beer-gift-detail.component.scss']
})
export class BeerGiftDetailComponent implements OnInit {

  userData: UserData;

  maxPoint = 950;
  actPoint = 0;
  actPercentagePoint = 0;

  constructor(private router: Router,
              private library: FaIconLibrary,
              private userService: UserService) {

    library.addIcons(faUser);
    library.addIcons(faQuestion);
    library.addIcons(faWindowClose);
    library.addIcons(faStar);
  }

  ngOnInit(): void {
    this.loadUserData();
    this.subsNewUserData();
  }

  redirect(path) {
    this.router.navigate([path]);
  }

  loadUserData() {
    this.userData = this.userService.getActUserData();
    this.actPoint = this.userData?.user_points?.score;
    this.setPoint();
  }

  subsNewUserData() {
    this.userService.userDataActNotifier$.subscribe( (userData: UserData) => {
      this.userData = userData;
      this.actPoint = userData.user_points.score;

      this.setPoint();
    });
  }

  setPoint() {
    this.actPercentagePoint = this.actPoint / this.maxPoint * 100;
  }

  openPage() {
    const https = 'https://';
    let url = this.userData.user_info.coupon_url;

    if (!this.userData.user_info.coupon_url.includes(https)) {
      url =  https + this.userData.user_info.coupon_url;
    }

    window.open(url, '_blank');
  }

}
