<div class="full-screen">
  <app-main-header></app-main-header>


  <div class="container main-container">
    <div class="row group-menu-item" *ngFor="let item of accessUserItems" (click)="redirect(item.path, getActualStepStatus(item.id))">

      <div class="col-8">
        <h4>{{item.name}}</h4>
        <fa-icon *ngIf="getActualStepStatus(item.id) === 0" class="icon" [icon]="['fas','lock']"></fa-icon>
        <fa-icon *ngIf="getActualStepStatus(item.id) === 1" class="icon" [icon]="['fas','lock-open']"></fa-icon>
        <fa-icon *ngIf="getActualStepStatus(item.id) === 2" class="icon" [icon]="['fas','check']"></fa-icon>
      </div>

      <div class="col-4 text-center">
        <img [src]="item.icon" />
      </div>

    </div>

    <div class="row">
      <div class="col-12"></div>
    </div>
  </div>
</div>
