<div class="container navbar-header">
  <div class="row">
    <div class="col-10">
      <h4><fa-icon class="icon" [icon]="['fas','user']"></fa-icon>{{userData?.user_info?.first_name}} {{userData?.user_info?.last_name}}</h4>
    </div>

    <div class="col-2 icon-action">
      <fa-icon [icon]="['fas','home']" size="2x" (click)="redirect('admin-menu')"></fa-icon>
    </div>

    <div class="col-12" *ngIf="userData.user_info.role === 'ADMIN'">
      <span class="badge text-bg-second">Administrator</span>
    </div>
  </div>
</div>
