import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {UserInfo} from '../../../interfaces/interfaces';

@Component({
  selector: 'app-login-viewer',
  templateUrl: './login-viewer.component.html',
  styleUrls: ['./login-viewer.component.scss']
})
export class LoginViewerComponent implements OnInit {

  user_name: string;
  user_password: string;
  user_access_name: string;
  invalidUser = false;
  userNotFound = false;

  constructor(private _userService: UserService,
              private _authService: AuthService,
              private _router: Router) { }

  ngOnInit(): void {
    this.user_name = this._userService.getActUserName();
  }

  submit() {
    this.userNotFound = false;
    this.invalidUser = false;

    this._userService.loginUser(this.user_name, this.user_password).subscribe((resp: UserInfo) => {
      this._userService.saveActUserInfo(resp);
      this._authService.setUserData(resp);

      this._userService.getUserByName(this.user_access_name).subscribe( data => {
        if (data) {
          this._router.navigate(['admin-user/' + data.external_id]);
        } else {
          this.invalidUser = true;
        }
      });
    }, (err) => {
      this.userNotFound = true;
    });
  }
}
