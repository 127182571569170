<app-unit-header [headerText]="'Team & I'" [headerDetail]="'You don’t have to stalk your colleagues on linkedin 😉 We did that instead of you.'"></app-unit-header>

<div class="container main-container">
  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>Team description</h5>
      <p class="text_p">{{teamData?.team?.description}}</p>
    </div>
  </div>

  <div class="row items-row-title">
    <div class="col-12 items-col">
      <h5>Team members</h5>
    </div>
  </div>

  <div class="row items-row-box" *ngFor="let member of teamData.members">
    <div class="col-4 items-col-img">
      <img src="{{ actURL + member.image }}" alt="{{ member.image }}" height="100%">
    </div>

    <div class="col-8 items-col">
      <div class="row">
        <div class="col-12 mb-1" (click)="navigate(member.url)">
          <b>{{member.name}}</b>
          <fa-icon *ngIf="member.url" class="icon" [icon]="['fab', 'linkedin']"></fa-icon>
        </div>
      </div>

      <div class="row">
        <div class="col-12 position">{{member.position}}</div>
      </div>

      <div class="row">
        <div class="col-12">{{member.description}}</div>
      </div>
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>Your turn!</h5>
      <p>„We would like to know you more as a person, therefore we also introduced us more personal. We self presented us in one sentence that describes our life. Please share yours at the bottom :)“</p>
    </div>
  </div>

<!--  <button *ngIf="!isStepCompleted()" id="next_page" type="button" class="btn items-button position-relative" (click)="redirect('culture-dress-code')">-->
<!--    Test yourself-->
<!--    <span class="position-absolute top-0 start-25 translate-middle badge rounded-pill items-button-badge">-->
<!--          200 points-->
<!--          <span class="visually-hidden">unread messages</span>-->
<!--        </span>-->
<!--  </button>-->

  <form [formGroup]="dataForm" enctype="multipart/form-data">

    <div class="row items-row">
      <div class="col-4 items-col">
        <h6 class="position-relative">
          Video
          <span class="position-absolute badge-position-middle translate-middle badge rounded-pill items-button-badge"> 250 points
            <span class="visually-hidden">unread messages</span>
          </span>
        </h6>
      </div>

      <div class="col-1 items-col"></div>

      <div class="col-7 items-col">
          <h6 *ngIf="userData?.user_data?.video">
            <span (click)="getFile(userData?.user_data?.video)" *ngIf="userData?.user_data?.video">({{userData?.user_data?.video.substring(0, 10)}}... )<fa-icon class="icon-action-down" [icon]="['fas','arrow-down']"></fa-icon></span>
          </h6>
      </div>

      <input class="form-control" type="file" id="formVideo" (change)="selectVideo($event)">
    </div>


    <div class="row items-row">
      <div class="col-4 items-col">
        <h6 class="position-relative">
          Photo
          <span class="position-absolute badge-position-middle translate-middle badge rounded-pill items-button-badge"> 150 points
            <span class="visually-hidden">unread messages</span>
          </span>
        </h6>
      </div>

      <div class="col-1 items-col"></div>

      <div class="col-7 items-col">
        <h6 *ngIf="userData?.user_data?.image">
          <span (click)="getFile(userData?.user_data?.image)" *ngIf="userData?.user_data?.image">({{userData?.user_data?.image.substring(0, 10)}}... )<fa-icon class="icon-action-down" [icon]="['fas','arrow-down']"></fa-icon></span>
        </h6>
      </div>

      <input class="form-control" type="file" id="formImage" (change)="selectFile($event)">
    </div>

    <div class="row items-row">
      <div class="col-12 items-col">
        <h6>Hobby</h6>
        <textarea type="text" class="form-control" id="hobby" formControlName="hobby"></textarea>
      </div>
    </div>

    <div class="row items-row">
      <div class="col-12 items-col">
        <h6>Sentence that describes your life</h6>
        <textarea type="text" class="form-control" id="personal_info" formControlName="personal_info"></textarea>
      </div>
    </div>

    <div class="row items-row">
      <div class="col-12 items-col">
        <h6>Favorite animal ?</h6>

        <div class="row mt-3">
          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteAnimal" value="dog">
              <img src="./assets/items-img/teamAndI/dog.svg" class="select-img" alt="image" />
            </label>
          </div>

          <div class="col-2"></div>

          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteAnimal" value="cat">
              <img src="./assets/items-img/teamAndI/cat.svg" class="select-img" alt="image" />
            </label>
          </div>
        </div>

      </div>
    </div>


    <div class="row items-row">
      <div class="col-12 items-col">
        <h6>Favorite color ?</h6>

        <div class="row mt-3">
          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteColor" value="black">
              <img src="./assets/items-img/teamAndI/black.svg" class="select-img" alt="image" />
            </label>
          </div>

          <div class="col-2"></div>

          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteColor" value="red">
              <img src="./assets/items-img/teamAndI/red.svg" class="select-img" alt="image" />
            </label>
          </div>
        </div>

      </div>
    </div>

    <div class="row items-row">
      <div class="col-12 items-col">
        <h6>Favorite Place ?</h6>

        <div class="row mt-3">
          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoritePlace" value="beach">
              <img src="./assets/items-img/teamAndI/ocean.png" class="select-img" alt="image" />
            </label>
          </div>

          <div class="col-2"></div>

          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoritePlace" value="mountain">
              <img src="./assets/items-img/teamAndI/mountains.png" class="select-img" alt="image" />
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row items-row">
      <div class="col-12 items-col">
        <h6>Favorite drink ?</h6>

        <div class="row mt-3">
          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteDrink" value="juice">
              <img src="./assets/items-img/teamAndI/juice.png" class="select-img" alt="image" />
            </label>
          </div>

          <div class="col-2"></div>

          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteDrink" value="water">
              <img src="./assets/items-img/teamAndI/water.png" class="select-img" alt="image" />
            </label>
          </div>
        </div>

      </div>
    </div>

    <div class="row items-row">
      <div class="col-12 items-col">
        <h6>Favorite trees ?</h6>

        <div class="row mt-3">
          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteTree" value="conifer">
              <img src="./assets/items-img/teamAndI/conifer.svg" class="select-img" alt="image" />
            </label>
          </div>

          <div class="col-2"></div>

          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteTree" value="decid">
              <img src="./assets/items-img/teamAndI/decid.svg" class="select-img" alt="image" />
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row items-row">
      <div class="col-12 items-col">
        <h6>Favorite Games ?</h6>

        <div class="row mt-3">
          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteGame" value="board">
              <img src="./assets/items-img/teamAndI/board.png" class="select-img" alt="image" />
            </label>
          </div>

          <div class="col-2"></div>

          <div class="col-5" align="center">
            <label>
              <input type="radio" formControlName="favoriteGame" value="video">
              <img src="./assets/items-img/teamAndI/video.png" class="select-img" alt="image" />
            </label>
          </div>
        </div>
      </div>
    </div>

  </form>

  <div class="row row-footer">
    <div class="col-6" align="left">
      <button id="back_page" type="button" class="btn items-button" (click)="redirectBack()">Back</button>
    </div>

    <div class="col-6" align="right">
      <button id="next_page" class="btn items-button" type="submit" (click)="upload()">Save</button>
    </div>
  </div>
</div>
