<div class="full-screen">

  <div class="header">
    <div class="container">
      <div class="row items-row-narrow-title">
        <app-header></app-header>
      </div>
    </div>
  </div>

  <div class="main-screen">

    <div class="container">
      <div class="row items-row-narrow-no">
        <div class="col-12 items-col-narrow-no-img" align="center">
          <img class="" src="../../../../assets/welcome.png" alt="image" />
        </div>
      </div>

      <div class="row items-row-narrow-title">
        <div class="col-12 items-col text-center">
          <form>
            <div class="form-group">
              <label for="name"><b>Surname</b></label>
              <input type="text" class="form-control mt-3" id="name" [(ngModel)]="user_name" placeholder="Username" name="name">
            </div>

            <div class="form-group mt-3">
              <label for="name"><b>Password</b></label>
              <input type="password" class="form-control mt-3" id="password" [(ngModel)]="user_password" placeholder="Password" name="password">
            </div>

            <div class="form-group mt-3">
              <label for="name"><b>Name of user</b></label>
              <input type="text" class="form-control mt-3" id="access_name" [(ngModel)]="user_access_name" placeholder="Password" name="access_name">
            </div>

            <div *ngIf="userNotFound" class="alert alert-primary mt-2" role="alert">
              Please enter a valid name and password.
            </div>
            <div *ngIf="invalidUser" class="alert alert-primary mt-2" role="alert">
              User with name "{{user_access_name}}" not found
            </div>

            <button type="button" class="btn items-button" (click)="submit()">Continue</button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">

  </div>
</div>




