<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="1"
  [test_question]="'1. Choose the right dresscode'"
  [test_img_src_1]="'./assets/items-img/culture_test_time/Business.png'"
  [test_img_src_1_correct_anw]="1"
  [test_img_src_2]="'./assets/items-img/culture_test_time/Casual.png'"
  [test_img_src_2_correct_anw]="0"
  [test_success_redirect]="'company-info'"
></app-unit-company-culture-test>
