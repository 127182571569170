import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit-company-culture-test-language',
  templateUrl: './unit-company-culture-test-language.component.html',
  styleUrls: ['./unit-company-culture-test-language.component.scss']
})
export class UnitCompanyCultureTestLanguageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
