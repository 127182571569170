<div class="container navbar-header-items">
  <div class="row">
    <div class="col-12">
      <h2>Company’s Culture</h2>
      <p>Shared values, goals and practices that characterize an organization.</p>
    </div>
  </div>
</div>

<div class="container">
  <div class="row items-row-awards">
    <div class="col-12 items-col" align="center">
      <img class="item-award" src="./assets/items-img/award.png" alt="image" />

      <h3>Congratulations!</h3>
      <p>You have read all the important information and receive 200 points. Keep it up!</p>

      <img class="item-award-points" src="./assets/items-img/points.png" alt="image" /><br>

      <button id="next_page" type="button" class="btn items-button-long" (click)="setStepComplete(true, 'gifts')">Collect</button>
    </div>
  </div>

</div>
