<app-main-header></app-main-header>

<div class="container main-container">

  <div class="row items-row-title">
    <div class="col-12 items-col text-center">
      <h3><b>Gifts</b></h3>
    </div>
  </div>

  <div class="row items-row-awards-gift">
    <div class="col-12 items-col item-award-gift" align="center">
      <img src="./assets/items-img/secret.png" alt="image" />

      <p>Fun fact about member of team</p>
      <fa-icon *ngIf="actPoint < 250" class="icon" [icon]="['fas','lock']"></fa-icon><h6>250 points</h6>

      <button id="collect2" type="button" class="btn items-button-long-80" [ngClass]=" actPoint >= 250  ? '' : 'disabled'" (click)="redirect('gift-fun-fact')" >Collect</button>
    </div>
  </div>

  <div class="row items-row-awards-gift">
    <div class="col-12 items-col item-award-gift" align="center">
      <img src="./assets/items-img/certificateTree.svg" alt="image" />

      <p>Tree reward</p>
      <fa-icon *ngIf="actPoint < 500" class="icon" [icon]="['fas','lock']"></fa-icon><h6>500 points</h6>

      <button id="collect4" type="button" class="btn items-button-long-80" [ngClass]=" actPoint >= 500  ? '' : 'disabled'" (click)="downloadLinkFile()">Download</button>
    </div>
  </div>

  <div class="row items-row-awards-gift">
    <div class="col-12 items-col item-award-gift" align="center">
      <img  src="./assets/items-img/beer.png" alt="image" />

      <p>Drink with team</p>
      <fa-icon *ngIf="actPoint < 600" class="icon" [icon]="['fas','lock']"></fa-icon><h6>600 points</h6>
      <button id="collect1" type="button" class="btn items-button-long-80" [ngClass]=" actPoint >= 600  ? '' : 'disabled'" (click)="redirect('gift-beer-detail')">Collect</button>
    </div>
  </div>

  <div class="row items-row-awards-gift">
    <div class="col-12 items-col item-award-gift" align="center">
      <img src="./assets/items-img/diploma.png" alt="image" />

      <p>Certificate of completion</p>
      <fa-icon *ngIf="actPoint < 750" class="icon" [icon]="['fas','lock']"></fa-icon><h6>750 points</h6>
      <button id="collect3" type="button" class="btn items-button-long-80" [ngClass]=" actPoint >= 750  ? '' : 'disabled'" (click)="getUserCertificate()">Download</button>
    </div>
  </div>


  <div class="row row-footer">
    <div class="col-12" align="left">
      <button id="back_page" type="button" class="btn items-button" (click)="redirect('')">Back</button>
    </div>
  </div>
</div>
