import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {faCheck, faCircle, faGlobe, faStar} from '@fortawesome/free-solid-svg-icons';
import {faSquare} from "@fortawesome/free-regular-svg-icons";
import {environment} from "../../../../../environments/environment";
import {UserService} from "../../../../services/user.service";
import {UnitComponent} from "../unit/unit.component";
import {StepService} from "../../../../services/step.service";
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';

const itemsConfig = environment.allConfigItems;

@Component({
  selector: 'app-unit-company-culture',
  templateUrl: './unit-company-culture.component.html',
  styleUrls: ['./unit-company-culture.component.scss']
})
export class UnitCompanyCultureComponent extends UnitComponent implements OnInit {

  itemStepID = itemsConfig[1].id;

  constructor(public library: FaIconLibrary, stepService: StepService, userService: UserService, router: Router, route: ActivatedRoute) {

    super(userService, stepService, router, route);

    library.addIcons(faStar);
    library.addIcons(faSquare);
    library.addIcons(faCheck);
    library.addIcons(faCircle);
    library.addIcons(faLinkedin);
    library.addIcons(faGlobe);
  }

  ngOnInit(): void {
  }
}
