<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="10"
  [test_question]="'10. How many nationalities are in our company?'"
  [test_img_src_1]="'./assets/items-img/culture_test_time/national25.png'"
  [test_img_src_1_correct_anw]="0"
  [test_img_src_2]="'./assets/items-img/culture_test_time/national40.png'"
  [test_img_src_2_correct_anw]="1"
  [test_success_redirect]="'employees'"
></app-unit-company-culture-test>
