<div class="full-screen">
  <app-admin-header></app-admin-header>

  <div class="container main-container">

    <div class="row mt-2">
      <div class="col-6" align="left">
        <button id="back_page" type="button" class="btn items-button" (click)="redirect('admin-menu')">
          <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
        </button>
      </div>

      <div class="col-6" align="right">
        <button id="user_page" type="button" class="btn items-button" (click)="redirect('admin-user-add')">Create user</button>
      </div>
    </div>

    <div class="row items-row">
      <div class="col-12">
        <h5><b>All users</b></h5>
      </div>
    </div>

    <div class="row group-menu-item"  *ngFor="let user of userList" [routerLink]="['/admin-user', user.external_id]" routerLinkActive="active">
      <div class="col-3">
        <fa-icon *ngIf="user.role === 'USER'" class="icon fa-2x" [icon]="['fas', 'user']"></fa-icon>
        <fa-icon *ngIf="user.role !== 'USER'" class="icon fa-2x" [icon]="['fas', 'user-edit']"></fa-icon>
      </div>

      <div class="col-9">
        <h4>{{user.first_name}} {{user.last_name}}</h4>
        <p><span *ngIf="user.location">{{user.location}}, </span> {{user.position}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12"></div>
    </div>

    <div class="row row-footer"></div>
  </div>
</div>
