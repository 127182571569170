import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../../../../services/auth.service';
import {UserData, UserInfo} from '../../../../../../../interfaces/interfaces';
import {UserService} from '../../../../../../../services/user.service';

@Component({
  selector: 'app-team-detail-auth',
  templateUrl: './team-detail-auth.component.html',
  styleUrls: ['./team-detail-auth.component.scss']
})
export class TeamDetailAuthComponent implements OnInit {

  paramTeamId: string;
  paramToken: string;

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _userService: UserService,
              private _authService: AuthService) { }

  ngOnInit(): void {
    this.paramTeamId = this._route.snapshot.params.teamId;
    this.paramToken = this._route.snapshot.params.token;

    if (this.paramTeamId && this.paramToken) {
      this._authService.setUserAccessToken(this.paramToken);

      this._userService.getActUser().subscribe((resp: UserData) => {
        this._userService.saveActUser(resp);
        this._authService.setUserInfo(resp);

        this._router.navigate(['admin-team', this.paramTeamId]);
      });
    } else {

      this._router.navigate(['login-admin']);
    }
  }

}
