<app-unit-header [headerText]="'Company Culture'" [headerDetail]="'Who we truly are, how and from where do we work.'"></app-unit-header>

<div class="container main-container">
  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>#DigitalToTheHeart</h5>
      <p>We are #digitaltotheheart. We unite Business and Technology to create the future of banking. To deliver the best services possible for Bank’s customers.</p>
      <img src="./assets/items-img/gifHashtags.gif" alt="image" />
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>#WayOfWork</h5>
      <p>“Digitalisation is the key to success in the world of finance. We take this opportunity to shape the bank through our products and solutions. We drive the change.” </p>
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>#DressCode</h5>
      <p>We are IT specialists but working with the bank, so take it seriously ;)</p>
      <img src="./assets/items-img/dresscode.png" alt="image" />
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>#Offices</h5>
      <p><fa-icon [icon]="['fas','circle']" size="xs"></fa-icon> 3 countries</p>
      <p><fa-icon [icon]="['fas','circle']" size="xs"></fa-icon> High standard facilities</p>
      <p><fa-icon [icon]="['fas','circle']" size="xs"></fa-icon> Great coffee</p>
      <img src="./assets/items-img/offices.gif" alt="image" />
    </div>
  </div>

  <div class="row items-row-narrow">
    <div class="col-12 items-col">
      <h5>Virtual tour of our offices:</h5>
      <p><fa-icon [icon]="['fas','circle']" size="xs"></fa-icon> Bratislava</p>
    </div>
  </div>

  <div class="row items-row-narrow">
    <div class="col-12 items-col" align="center">
      <img src="./assets/items-img/vienna.png" alt="image" />
      <button id="virtual_page" type="button" class="btn items-button-outlin-long" (click)="openNewPage('https://my.matterport.com/show/?m=t3ZrbnuRUN5')">Virtual tour</button>
    </div>
  </div>

  <div class="row items-row-narrow">
    <div class="col-12 items-col">
      <p class="mt-3"><fa-icon [icon]="['fas','circle']" size="xs"></fa-icon> Vienna</p>
    </div>
  </div>

  <div class="row items-row-narrow">
    <div class="col-12 items-col" align="center">
      <img src="./assets/items-img/bratislava.png" alt="image" />
      <button id="virtual_page3" type="button" class="btn items-button-outlin-long" (click)="openNewPage('https://www.360perspektiven.com/projekte/erste-bank/')">Virtual tour</button>
    </div>
  </div>

  <div class="row row-footer">
    <div class="col-6" align="left">
      <button id="back_page" type="button" class="btn items-button" (click)="redirect('')">Back</button>
    </div>

    <div class="col-6" align="right">
      <button id="next_page" type="button" class="btn items-button position-relative" (click)="redirect('culture-dress-code')">
        Test yourself
        <span class="position-absolute top-0 start-25 translate-middle badge rounded-pill items-button-badge">
          200 points
          <span class="visually-hidden">unread messages</span>
        </span>
      </button>
    </div>
  </div>
</div>
