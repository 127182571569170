<app-unit-header [headerText]="'Our topics'" [headerDetail]="'This is just a sneak peak of what we do. In case you are interested in these solutions, click on any of them and you can write us an email'"></app-unit-header>

<div class="container main-container">

  <div class="row items-row-awards-gift" (click)="mailTo()">
    <div class="col-12 items-col item-award-gift" align="center">
      <img src="./assets/items-img/topics/networking.svg" alt="image" />

      <h4>Neural networks/AI</h4>

      <p>Not only data modeling, but also transaction categorization, text understanding or bills understanding on item level!
        With the consent of the client (in a pilot phase so far), they can see not only how much they spent in e.g. Tesco or Bulla for Groceries,
        but also how much meat, what kind of yogurt or how many kilos of bananas they bought in July. And by the way, was it a bio banana and locally produced yogurt?
        If client wants and participates in the pilot, we can help them by showing all of this data. If you want to know more, search for “Blocker” app (android only).</p>
    </div>
  </div>

  <div class="row items-row-awards-gift" (click)="mailTo()">
    <div class="col-12 items-col item-award-gift" align="center">
      <img src="./assets/items-img/topics/chatbot.svg" alt="image" />

      <h4>Chatbots/Voicebots</h4>

      <p>Solution for our colleagues to navigate in all the possible questions related to our company,
        internal processes, skills, office, equipment, HR, news and much more. Let's try ED Chatbot buddy available via Teams.
        It can also help you to find colleagues based on their skills, suggest what to eat even at home or give you some (corporate) jokes.
        And yes, we have external chatbots for banks as well, starting from simple scheduling systems at the branch,
        Data scientists support  up to Vesna (holographic voicebot at the branch). Do you know her already? https://www.slsp.sk/sk/ludia/vesna</p>
    </div>
  </div>

  <div class="row items-row-awards-gift" (click)="mailTo()">
    <div class="col-12 items-col item-award-gift" align="center">
      <img src="./assets/items-img/topics/metaverse.svg" alt="image" />

      <h4>Metaverse</h4>

      <p>Presence of Erste in some of the metaverse ecosystems? Yes, we are working on it.
        But not only to be there. We want to educate people and teach them financial literacy there as well.
        Frankly, are bit so far yet, but... ... before metaverse was a hype,
        we introduced virtualized branch where every client can customize it and meet there with their advisor,
        talk to each other via embedded meeting functions, chat together, share the screen,
        and even talk about the product digitalized at virtual table. Interesting, right?
        Client signature with instant product activation is available as well! Even though it was only a working prototype,
        reactions were great! If you want to know more about this, you have to join us and meet the Innovations guys.
        They are eager to share 😉</p>
    </div>
  </div>

  <div class="row items-row-awards-gift" (click)="mailTo()">
    <div class="col-12 items-col item-award-gift" align="center">
      <img src="./assets/items-img/topics/quantumComp.svg" alt="image" />

      <h4>Quantum Computing</h4>

      <p>It is not only a hype or future trend. We are working on Quantum computers already.
        True it is still a research activity, but we dedicated 20 experts from over the group to learn
        and work with Quantum framework from IBM (Qiskit). Indeed a possible gamechanger in years to come,
        but we know we have to start soon to be at the edge of technological progress. Want to join this team?
        Inno guys will onboard you 😉</p>
    </div>
  </div>

  <div class="row row-footer">
    <div class="col-6" align="left">
      <button id="back_page" type="button" class="btn items-button" (click)="redirect('')">Back</button>
    </div>

    <div class="col-6" align="right">
      <button *ngIf="!isStepCompleted()" id="next_page" type="button" class="btn items-button position-relative" (click)="setStepComplete(true, '')">
        Done
        <span class="position-absolute top-0 start-25 translate-middle badge rounded-pill items-button-badge">
          50 points
          <span class="visually-hidden">unread messages</span>
        </span>
      </button>
    </div>
  </div>
</div>
