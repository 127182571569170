<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="11"
  [test_question]="'11. How many employees do we have?'"
  [test_img_src_1]="'./assets/items-img/culture_test_time/2050.png'"
  [test_img_src_1_correct_anw]="1"
  [test_img_src_2]="'./assets/items-img/culture_test_time/1025.png'"
  [test_img_src_2_correct_anw]="0"
  [test_success_redirect]="'culture-award'"
></app-unit-company-culture-test>
