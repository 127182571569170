import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../../../../services/user.service';
import {FormBuilder} from '@angular/forms';
import {Actions} from '../../../../../../../interfaces/interfaces';

@Component({
  selector: 'app-new-user-action',
  templateUrl: './new-user-action.component.html',
  styleUrls: ['./new-user-action.component.scss']
})
export class NewUserActionComponent implements OnInit {

  actions: Actions[] = [];
  description: string;

  actionForm = this._formBuilder.group({
    type: '',
    date: '',
  });

  constructor(private _router: Router,
              private _userService: UserService,
              private _route: ActivatedRoute,
              private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this._userService.getActions().subscribe( data => {
      this.actions = data;
    });
  }

  ScheduleAction() {
    const formData: FormData = new FormData();

    formData.append('type', this.actionForm.controls.type.value);
    formData.append('date', this.actionForm.controls.date.value);
    formData.append('user_external_id', this._route.snapshot.params.id);

    this._userService.addUserAction(formData).subscribe( resp => {
      this.redirectBack();
    });
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

  selectChangeHandler(event){
    this.description = this.actions.filter(action => action.id == event.target.value)[0].description ;
  }

  redirectBack() {
    this.redirect('admin-user-actions/' + this._route.snapshot.params.id);
  }

}
