<div class="full-screen">
  <app-admin-header></app-admin-header>

  <div class="container main-container">

    <div class="row items-row-title-p mt-2">
      <div class="col-12">
        <p><b>Schedule new user action</b></p>
      </div>
    </div>

    <form [formGroup]="actionForm">
      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6>Type of action</h6>
          <select  class="form-control" id="type" formControlName="type" (change)="selectChangeHandler($event)">
            <option *ngFor="let action of actions" value="{{action.id}}">{{action.name}}</option>
          </select>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <p>{{description}}</p>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6>Date</h6>
          <input id="date" type="date" class="form-control" formControlName="date">
        </div>
      </div>

      <div class="row row-footer">
        <div class="col-6" align="left">
          <button id="back_page" type="button" class="btn items-button" (click)="redirectBack()">Back</button>
        </div>

        <div class="col-6" align="right">
          <button id="user_page" type="button" class="btn items-button" (click)="ScheduleAction()">Schedule action</button>
        </div>
      </div>
    </form>
  </div>
</div>
