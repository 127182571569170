<div class="full-screen">

  <div class="header">
    <div class="container">
      <div class="row items-row-narrow-title">
        <app-header></app-header>
      </div>
    </div>
  </div>

  <div class="main-screen">

    <div class="container">
      <div class="row items-row-narrow-no">
        <div class="col-12 items-col-narrow-no-img" align="center">
          <img class="" src="./../../../../assets/welcome.png" alt="image" />
        </div>
      </div>

      <div class="row items-row-narrow-title">
        <div class="col-12 items-col text-center">
          <h1>Congratulations</h1>
        </div>
      </div>

      <div class="row items-row-narrow">
        <div class="col-12 items-col-narrow-no-img text-center">
          <p>It’s great that you have solved our puzzle! There are only a few steps to be done.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="container">
      <div class="row items-row-narrow-title">
        <div class="col-12" align="center">
          <fa-icon class="icon" [icon]="['far','dot-circle']" size="l"></fa-icon>
          <fa-icon class="icon" [icon]="['fas','circle']" size="l"></fa-icon>
          <fa-icon class="icon" [icon]="['fas','circle']" size="l"></fa-icon>
          <fa-icon class="icon" [icon]="['fas','circle']" size="l"></fa-icon>
        </div>
      </div>

      <div class="row items-row-narrow-footer">
        <div class="col-6" align="left">
          <button id="skip" type="button" class="btn bt-footer items-button" (click)="redirect('')">Skip</button>
        </div>

        <div class="col-6" align="right">
          <button id="next_page" type="button" class="btn bt-footer items-button" (click)="redirect('attention')">Next</button>
        </div>
      </div>
    </div>
  </div>
</div>




