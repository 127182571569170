<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="6"
  [test_question]="'6. Most common employee name (men)?'"
  [test_img_src_1]="'./assets/items-img/culture_test_time/Thomas.png'"
  [test_img_src_1_correct_anw]="1"
  [test_img_src_2]="'./assets/items-img/culture_test_time/Lukas.png'"
  [test_img_src_2_correct_anw]="0"
  [test_success_redirect]="'job-title'"
></app-unit-company-culture-test>
