import { Component, OnInit } from '@angular/core';
import {faCheck, faQuestion, faQuestionCircle, faStar, faUser} from '@fortawesome/free-solid-svg-icons';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {UserData} from '../../interfaces/interfaces';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  userData: UserData;
  maxPoint = 950;
  actPoint = 0;
  actPercentagePoint = 0;

  constructor(private library: FaIconLibrary,
              private router: Router,
              private userService: UserService) {

    library.addIcons(faUser);
    library.addIcons(faQuestion);
    library.addIcons(faQuestionCircle);
    library.addIcons(faCheck);
    library.addIcons(faStar);
  }

  ngOnInit(): void {
    this.loadUserData();
    this.subsNewUserData();
  }

  loadUserData() {
    this.userData = this.userService.getActUserData();
    this.actPoint = this.userData?.user_points?.score;
    this.setPoint();
  }

  subsNewUserData() {
    this.userService.userDataActNotifier$.subscribe( (userData: UserData) => {
      this.userData = userData;
      this.actPoint = userData.user_points.score;

      this.setPoint();
    });
  }

  setPoint() {
    this.actPercentagePoint = this.actPoint / this.maxPoint * 100;
  }

  redirect(path: string) {
    this.router.navigate([path]);
  }

}
