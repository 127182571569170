<div class="full-screen">
  <app-admin-header></app-admin-header>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdropMenu" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropMenuLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="align-items: flex-end;">
      <div class="modal-content">
        <div class="modal-body">
          <p class="secondary-color-test">User account manipulation</p>

          <button id="edit_page" type="button" class="btn items-button" data-bs-dismiss="modal" [routerLink]="['/admin-user-edit', userData?.user_info?.external_id]">
            <fa-icon class="" [icon]="['fas','edit']"></fa-icon> Edit
          </button>

          <button id="reset_page" type="button" class="btn items-button" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#staticBackdropReset">
            <fa-icon class="" [icon]="['fas','power-off']"></fa-icon> Reset
          </button>

          <button id="delete_page" type="button" class="btn items-button" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            <fa-icon class="" [icon]="['fas','trash']"></fa-icon> Delete
          </button>
        </div>
        <div class="modal-footer">

          <button type="button" class="btn items-button" data-bs-dismiss="modal">
            <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Close
          </button>

          <button id="actions_page" type="button" class="btn items-button" data-bs-dismiss="modal" [routerLink]="['/admin-user-actions', userData?.user_info?.external_id]">
            <fa-icon class="" [icon]="['fas','user-clock']"></fa-icon> Actions
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="align-items: flex-end;">
      <div class="modal-content">
        <div class="modal-body">
          <p class="secondary-color-test">Do you want to delete selected user?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn items-button" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn items-button" data-bs-dismiss="modal" (click)="deleteUser()">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdropReset" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabelReset" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " style="align-items: flex-end;">
      <div class="modal-content">
        <div class="modal-body">
          <p class="secondary-color-test">Do you want to reset the progress of the selected user?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn items-button" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn items-button" data-bs-dismiss="modal" (click)="resetUser()">Reset</button>
        </div>
      </div>
    </div>
  </div>

  <div class="container main-container mt-3">

    <div class="row items-admin-row-p">

      <div class="col-8">
        <div class="row items-admin-row-p mt-2">
          <div class="col-12">
            <p>Name:</p>
          </div>
        </div>

        <div class="row items-admin-row-p">
          <div class="col-12">
            <h4>{{userData?.user_info.first_name}} {{userData?.user_info.last_name}}</h4>
          </div>
        </div>

        <div class="row items-admin-row-p" *ngIf="userData?.user_info.email">
          <div class="col-12">
            <p>Email:</p>
          </div>
        </div>

        <div class="row items-admin-row-p" *ngIf="userData?.user_info.email">
          <div class="col-12">
            <h6>{{userData?.user_info.email}}</h6>
          </div>
        </div>

        <div class="row items-admin-row-p">
          <div class="col-12">
            <p>Team:</p>
          </div>
        </div>

        <div class="row items-admin-row-p">
          <div class="col-12">
            <span class="badge text-bg-second">{{userData?.user_team.name}}</span>
          </div>
        </div>
      </div>

      <div class="col-4">
        <img class="items-admin-row-img" src="../../../../../../../../assets/admin-img/icon-person2.png" alt="image" />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <h5><b>User information</b></h5>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <h6 class="title-group"><b>Basic user profile</b></h6>
      </div>
    </div>

    <div class="row items-admin-row-p">
      <div class="col-6">
        <p>Position:</p>
        <h6>{{userData?.user_info.position}}</h6>
      </div>

      <div class="col-6">
        <p>Location:</p>
        <h6>{{userData?.user_info.location}}</h6>
      </div>
    </div>

    <div class="row items-admin-row-p">
      <div class="col-6">
        <p>Employment type:</p>
        <h6>{{getEmpTypeText(userData?.user_info.emp_type)}}</h6>
      </div>

      <div class="col-6">
        <p>Manager contact:</p>
        <h6>{{userData?.user_info.manager_email}}</h6>
      </div>
    </div>

    <div class="row items-admin-row-p">
      <div class="col-6">
        <p>First day in work:</p>
        <h6>{{userData?.user_info.first_day_in_work | date}}</h6>
      </div>

      <div class="col-6">
        <p>Date created:</p>
        <h6>{{userData?.user_info.created_at | date}}</h6>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <h6 class="title-group"><b>User steps</b></h6>
      </div>
    </div>

    <div class="row items-admin-row-p" *ngIf="userData?.user_steps && userData?.user_steps.length > 0">
      <div class="col-12">
        <ul>
          <li *ngFor="let step of userData.user_steps">
            <h6> {{step.name}}
              <fa-icon *ngIf="step.step_status == 2" class="icon"[icon]="['fas','check']"></fa-icon>
            </h6>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-4" *ngIf="userData?.user_action_scheduled && userData?.user_action_scheduled.length > 0">
      <div class="col-12">
        <h6 class="title-group"><b>Scheduled actions</b></h6>
      </div>
    </div>

    <div class="row items-admin-row-p" *ngIf="userData?.user_action_scheduled && userData?.user_action_scheduled.length > 0">
      <div class="col-12">
        <ul>
          <li *ngFor="let action of userData.user_action_scheduled">
            <h6> {{action.date |date}} ( {{action.name}} )</h6>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-3" *ngIf="userData?.user_info.role === 'USER'">
      <div class="col-12">
        <h5><b>User personal information</b></h5>
      </div>
    </div>

    <div class="row items-row-box" *ngIf="userData?.user_info.role === 'USER'">

      <div class="row mt-3">
        <div class="col-12">
          <h6 class="title-group"><b>User profile:</b></h6>
        </div>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-12">
          <p>Hobby:</p>
        </div>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-12">
          <h6>{{userData?.user_data?.hobby}}</h6>
        </div>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-12">
          <p>Personal info:</p>
        </div>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-12">
          <h6>{{userData?.user_data?.personal_info}}</h6>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h6 class="title-group"><b>User uploaded files:</b></h6>
        </div>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-6">
          <p>Image:</p>
          <h6>{{userData?.user_data?.image}}<fa-icon (click)="getFile(userData?.user_data?.image)" *ngIf="userData?.user_data?.image" class="icon-action-down" [icon]="['fas','arrow-down']"></fa-icon></h6>
        </div>

        <div class="col-6">
          <p>Video:</p>
          <h6>{{userData?.user_data?.video}}<fa-icon (click)="getFile(userData?.user_data?.video)" *ngIf="userData?.user_data?.video" class="icon-action-down" [icon]="['fas','arrow-down']"></fa-icon></h6>
        </div>
      </div>

      <div class="row items-admin-row-p mt-4">
        <h6 class="title-group"><b>User selected data:</b></h6>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-6">
          <p>Hardware selected:</p>
          <h6>{{getUserHardware(userData?.user_hardware)}}</h6>
        </div>

        <div class="col-6">
          <p>Selected meal plan:</p>
          <h6>{{userData?.user_meal?.name}}</h6>
        </div>
      </div>

      <div class="row items-admin-row-p mt-4">
        <h6 class="title-group"><b>User preferences:</b></h6>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-6">
          <p>Favorite animal:</p>
          <h6>{{userData?.user_data?.favorite_animal}}</h6>
        </div>

        <div class="col-6">
          <p>Favorite color:</p>
          <h6>{{userData?.user_data?.favorite_color}}</h6>
        </div>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-6">
          <p>Favorite drink:</p>
          <h6>{{userData?.user_data?.favorite_drink}}</h6>
        </div>

        <div class="col-6">
          <p>Favorite tree:</p>
          <h6>{{userData?.user_data?.favorite_tree}}</h6>
        </div>
      </div>

      <div class="row items-admin-row-p">
        <div class="col-6">
          <p>Favorite game:</p>
          <h6>{{userData?.user_data?.favorite_game}}</h6>
        </div>

        <div class="col-6">
          <p>Favorite place:</p>
          <h6>{{userData?.user_data?.favorite_place}}</h6>
        </div>
      </div>

    </div>

    <div class="row row-footer" *ngIf="actUser.user_info.role === 'ADMIN'">
      <div class="col-4" align="left">
        <button id="back_page" type="button" class="btn items-button" (click)="redirect('admin-users')">
          <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
        </button>
      </div>

      <div class="col-8" align="right">
        <button id="menu_page" type="button" class="btn items-button" data-bs-toggle="modal" data-bs-target="#staticBackdropMenu">
          <fa-icon class="icon" [icon]="['fas','bars']"></fa-icon>
        </button>
      </div>
    </div>

    <div class="row row-footer mb-2"></div>

  </div>
</div>
