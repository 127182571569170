import { Component, OnInit } from '@angular/core';
import {Team, UserData} from '../../../../../../../interfaces/interfaces';
import {Router} from '@angular/router';
import {TeamService} from '../../../../../../../services/team.service';
import {UserService} from '../../../../../../../services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-new-team',
  templateUrl: './new-team.component.html',
  styleUrls: ['./new-team.component.scss']
})
export class NewTeamComponent implements OnInit {

  teamForm: FormGroup;

  constructor(private _router: Router,
              private _teamService: TeamService,
              private _userService: UserService,
              private _formBuilder: FormBuilder,
              private _library: FaIconLibrary) {

    _library.addIcons(faArrowCircleLeft);
  }

  ngOnInit(): void {
    this.teamForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });
  }

  createTeam() {
    const formData: FormData = new FormData();

    if (this.teamForm.invalid) {
      return;
    }

    formData.append('name', this.teamForm.controls.name.value);
    formData.append('description', this.teamForm.controls.description.value);

    this._teamService.createNewTeam(formData).subscribe((resp: Team) => {
      this._router.navigate(['/admin-team', resp.external_id]);
    });
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

  get formControls() {
    return this.teamForm.controls;
  }

}
