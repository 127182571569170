<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="4"
  [test_question]="'4. Female employment in Erste Digital?'"
  [test_img_src_1]="'./assets/items-img/culture_test_time/25.png'"
  [test_img_src_1_correct_anw]="1"
  [test_img_src_2]="'./assets/items-img/culture_test_time/5.png'"
  [test_img_src_2_correct_anw]="0"
  [test_success_redirect]="'average-age'"
></app-unit-company-culture-test>
