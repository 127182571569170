import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsUserGuard implements CanActivate {
  constructor(private _auth: AuthService,
              private _router: Router) {
  }

  canActivate(): boolean {
    if (!this._auth.isAuthenticated() || this._auth.getActUser().user_info.role !== 'USER') {
      this._router.navigate(['login-user']);
      return false;
    }

    return true;
  }
}
