<div class="full-screen">
  <app-admin-header></app-admin-header>

  <div class="container main-container">

    <div class="row items-row-title-p mt-3">
      <div class="col-12">
        <h6><b>Edit user</b></h6>
        <p>Please avoid using personal data (GDPR)</p>
      </div>
    </div>

    <form [formGroup]="userForm">
      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6><span class="requiredInput">*</span>First name</h6>
          <input id="first_name" type="text" class="form-control" formControlName="first_name">
        </div>
        <div *ngIf="formControls.first_name.invalid && (formControls.first_name.dirty || formControls.first_name.touched)" class="error">
          <div *ngIf="formControls.first_name.errors.required">First name is required.</div>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6><span class="requiredInput">*</span>Last name</h6>
          <input id="last_name" type="text" class="form-control" formControlName="last_name">
        </div>
        <div *ngIf="formControls.last_name.invalid && (formControls.last_name.dirty || formControls.last_name.touched)" class="error">
          <div *ngIf="formControls.last_name.errors.required">Last name is required.</div>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6><span class="requiredInput">*</span>Email</h6>
          <input id="email" type="email" class="form-control" formControlName="email">
        </div>
        <div *ngIf="formControls.email.invalid && (formControls.email.dirty || formControls.email.touched)" class="error">
          <div *ngIf="formControls.email.errors.required">Email is required.</div>
          <div *ngIf="formControls.email.errors.email">Invalid email format.</div>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col">
          <h6><span class="requiredInput">*</span>Manager email</h6>
          <input id="manager_email" type="email" class="form-control" formControlName="manager_email" placeholder="">
        </div>
        <div *ngIf="formControls.manager_email.invalid && (formControls.manager_email.dirty || formControls.manager_email.touched)" class="error">
          <div *ngIf="formControls.manager_email.errors.required">Email is required.</div>
          <div *ngIf="formControls.manager_email.errors.email">Invalid email format.</div>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6><span class="requiredInput">*</span>Location</h6>
          <select  class="form-control" id="location" formControlName="location" placeholder="">
            <option *ngFor="let location of locations" value="{{location.name}}"> {{location.name}} </option>
          </select>
        </div>
        <div *ngIf="formControls.location.invalid && (formControls.location.dirty || formControls.location.touched)" class="error">
          <div *ngIf="formControls.location.errors.required">Location is required.</div>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6><span class="requiredInput">*</span>Employment type</h6>
          <select  class="form-control" id="emp_type" formControlName="emp_type">
            <option value="PART_TIME"> Part time </option>
            <option value="FULL_TIME"> Full time </option>
          </select>
        </div>
        <div *ngIf="formControls.emp_type.invalid && (formControls.emp_type.dirty || formControls.emp_type.touched)" class="error">
          <div *ngIf="formControls.emp_type.errors.required">Employment type is required.</div>
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6><span class="requiredInput">*</span>Position</h6>
          <select  class="form-control" id="position" formControlName="position">
            <option *ngFor="let position of userPositions" value="{{position.id}}"> {{position.name}} </option>
          </select>
        </div>
        <div *ngIf="formControls.position.invalid && (formControls.position.dirty || formControls.position.touched)" class="error">
          <div *ngIf="formControls.position.errors.required">Position is required.</div>
        </div>
        <div id="textInfoPosition" class="form-text">
          Position is connected to hardware options during on boarding process.
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6><span class="requiredInput">*</span>Team</h6>
          <select  class="form-control" id="team" formControlName="team">
            <option *ngFor="let team of teams" value="{{team.id}}"> {{team.name}} </option>
          </select>
        </div>
        <div *ngIf="formControls.team.invalid && (formControls.team.dirty || formControls.team.touched)" class="error">
          <div *ngIf="formControls.team.errors.required">Team is required.</div>
        </div>
        <div id="textInfoTeam" class="form-text">
          If you cannot find your team, pls create first.
        </div>
      </div>

      <div class="row items-admin-row">
        <div class="col-12 items-col-admin-form">
          <h6><span class="requiredInput">*</span>First day in work</h6>
          <input id="first_day" type="date" class="form-control" formControlName="first_day">
        </div>
        <div *ngIf="formControls.first_day.invalid && (formControls.first_day.dirty || formControls.first_day.touched)" class="error">
          <div *ngIf="formControls.first_day.errors.required">First day in work is required.</div>
        </div>
      </div>

<!--      <div class="row items-admin-row">-->
<!--        <div class="col-12 items-col-admin-form">-->
<!--          <h6>Coupon drink url</h6>-->
<!--          <input id="coupon_url" type="text" class="form-control" formControlName="coupon_url">-->
<!--        </div>-->
<!--        <div id="textInfo" class="form-text">-->
<!--          Insert a link with a poll for a date for a "beer" or agree in person (url format - www...)-->
<!--        </div>-->
<!--      </div>-->

      <div class="row items-admin-row">
        <div class="col-12"></div>
      </div>

      <div class="row row-footer">
        <div class="col-6" align="left">
          <button id="back_page" type="button" class="btn items-button" (click)="redirect()">
            <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
          </button>
        </div>

        <div class="col-6" align="right">
          <button id="user_page" type="button" class="btn items-button" (click)="saveUser()" [disabled]="userForm.invalid">Save user</button>
        </div>
      </div>

    </form>
  </div>
</div>
