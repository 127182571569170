<app-unit-header [headerText]="'First Steps'" [headerDetail]="'What else needs to be done on your way to become a valid member of our Erste Digital crew.'"></app-unit-header>

<div class="container main-container">

  <div class="row items-row">
    <div class="col-12 items-col">
     <p>We are pleased to have you as our future new colleague. It all started with you accepting our offer though it for sure does not end there.
       Bellow you can find few of the tasks that are still head of you.</p>
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>You have already:</h5>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','check']" size="l"></fa-icon></div>
        <div class="col-11"><p>Accepted the offer</p></div>
      </div>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','check']" size="l"></fa-icon></div>
        <div class="col-11"><p>Filled out the form with your personal details</p></div>
      </div>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','check']" size="l"></fa-icon></div>
        <div class="col-11"><p>Provided your photo for your future ID access card</p></div>
      </div>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','check']" size="l"></fa-icon></div>
        <div class="col-11"><p>Signed the formal commitment</p></div>
      </div>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','check']" size="l"></fa-icon></div>
        <div class="col-11"><p>Received onboarding package with the maze</p></div>
      </div>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','check']" size="l"></fa-icon></div>
        <div class="col-11"><p>Solved the maze and came here (congrats!)</p></div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <img src="./assets/items-img/first_steps/firstStepImg.png" alt="image" />
        </div>
      </div>
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>What will happen next:</h5>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','circle']" size="xs"></fa-icon></div>
        <div class="col-11"><p>Receive onboarding mail from HR</p></div>
      </div>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','circle']" size="xs"></fa-icon></div>
        <div class="col-11"><p>Sign employment contract </p></div>
      </div>
      <div class="row">
        <div class="col-1"><fa-icon [icon]="['fas','circle']" size="xs"></fa-icon></div>
        <div class="col-11"><p>Finish this newcomer journey and enjoy rewards</p></div>
      </div>
    </div>
  </div>

  <div class="row items-row">
    <div class="col-12 items-col">
      <h5>What can you look forward to on your first days of work?</h5>

      <div class="row">
        <div class="col-6 items-sub-col">
          <div class="items-sec-sub-col" align="center">
            <img src="./assets/items-img/first_steps/HR.svg" alt="image" />
            <p>HR colleagues will brief you</p>
          </div>
        </div>

        <div class="col-6 items-sub-col">
          <div class="items-sec-sub-col" align="center">
            <img src="./assets/items-img/first_steps/Equipment.svg" alt="image" />
            <p>Laptop, iPhone, docking station, ...</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 items-sub-col">
          <div class="items-sec-sub-col" align="center">
            <img src="./assets/items-img/first_steps/Card.svg" alt="image" />
            <p>Access to all buildings and car/bike parking</p>
          </div>
        </div>

        <div class="col-6 items-sub-col">
          <div class="items-sec-sub-col" align="center">
            <img src="./assets/items-img/first_steps/Working_env.svg" alt="image" />
            <p>Ergonomic chair, adjustable table, ...</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 items-sub-col">
          <div class="items-sec-sub-col" align="center">
            <img src="./assets/items-img/first_steps/Buddy.svg" alt="image" />
            <p>Buddy and eBuddy at your disposal to understand everything and know people :)</p>
          </div>
        </div>

        <div class="col-6 items-sub-col">
          <div class="items-sec-sub-col" align="center">
            <img src="./assets/items-img/first_steps/Get2know.svg" alt="image" />
            <p>Great coffe, relax zones, cozzy kitchens, view, ...</p>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="row row-footer">
    <div class="col-6" align="left">
      <button id="back_page" type="button" class="btn items-button" (click)="redirect('')">Back</button>
    </div>

    <div class="col-6" align="right">
      <button *ngIf="!isStepCompleted()" id="next_page" type="button" class="btn items-button position-relative" (click)="setStepComplete(true, '')">
        Done
        <span class="position-absolute top-0 start-25 translate-middle badge rounded-pill items-button-badge">
          50 points
          <span class="visually-hidden">unread messages</span>
        </span>
      </button>
    </div>
  </div>
</div>
