import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit-company-culture-test-dress-code',
  templateUrl: './unit-company-culture-test-dress-code.component.html',
  styleUrls: ['./unit-company-culture-test-dress-code.component.scss']
})

export class UnitCompanyCultureTestDressCodeComponent implements OnInit {

  constructor(){}

  ngOnInit(): void {
  }

}
