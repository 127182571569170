<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="2"
  [test_question]="'2. Our firm is more about .... '"
  [test_img_src_1]="'./assets/items-img/culture_test_time/Banking.png'"
  [test_img_src_1_correct_anw]="0"
  [test_img_src_2]="'./assets/items-img/culture_test_time/IT.png'"
  [test_img_src_2_correct_anw]="1"
  [test_success_redirect]="'language'"
></app-unit-company-culture-test>
