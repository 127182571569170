import { Component, OnInit } from '@angular/core';
import {UserInfo} from "../../../interfaces/interfaces";
import {UserService} from "../../../services/user.service";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {

  user_name: string;
  user_password: string;
  userNotFound = false;

  constructor(private _userService: UserService,
              private _authService: AuthService,
              private _router: Router) { }

  ngOnInit(): void {
    this.user_name = this._userService.getActUserName();
  }

  submit() {
    this.userNotFound = false;

    this._userService.loginUser(this.user_name, this.user_password).subscribe((resp: UserInfo) => {
      this._userService.saveActUserInfo(resp);
      this._authService.setUserData(resp);
      this._router.navigate(['admin-menu']);
    }, (err) => {
      this.userNotFound = true;
    });
  }

}
