<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="5"
  [test_question]="'5. Total average age of employees?'"
  [test_img_src_1]="'./assets/items-img/culture_test_time/23age.png'"
  [test_img_src_1_correct_anw]="0"
  [test_img_src_2]="'./assets/items-img/culture_test_time/45age.png'"
  [test_img_src_2_correct_anw]="1"
  [test_success_redirect]="'popular-name'"
></app-unit-company-culture-test>
