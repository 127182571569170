import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {

  constructor(private _auth: AuthService,
              private _router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') }).clone({
      setHeaders: {
        Authorization: `${this._auth.getToken()}`
      }
    });

    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this._router.navigate(['/login-admin']);
        }

        return throwError(error);
      })
    );
  }
}
