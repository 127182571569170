import { Component, OnInit } from '@angular/core';
import {UserData} from '../../../../../../../interfaces/interfaces';
import {UserService} from '../../../../../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StepService} from '../../../../../../../services/step.service';
import {saveAs} from 'file-saver';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {
  faArrowCircleLeft,
  faArrowDown,
  faBars, faCheck,
  faEdit,
  faPowerOff,
  faTrash,
  faUserClock
} from '@fortawesome/free-solid-svg-icons';
import {AuthService} from '../../../../../../../services/auth.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  userData: UserData;
  actUser: UserData;

  constructor(private _userService: UserService,
              private _library: FaIconLibrary,
              private _route: ActivatedRoute,
              private _stepService: StepService,
              private _authService: AuthService,
              private _router: Router) {

    _library.addIcons(faArrowDown);
    _library.addIcons(faBars);
    _library.addIcons(faArrowCircleLeft);
    _library.addIcons(faEdit);
    _library.addIcons(faTrash);
    _library.addIcons(faUserClock);
    _library.addIcons(faPowerOff);
    _library.addIcons(faCheck);
  }

  ngOnInit(): void {
   this.actUser = this._authService.getActUser();

   this._userService.getUserData(this._route.snapshot.params.id).subscribe( data => {
      if (data) {
        this.userData = data;
      }
    });
  }

  deleteUser() {
    this._userService.deleteUser(this.userData.user_info.external_id).subscribe( data => {
      this.redirect('admin-users');
    });
  }

  resetUser() {
    this._userService.resetUser(this.userData.user_info.external_id).subscribe( data => {
      this.redirect('admin-users');
    });
  }

  getFile(fileName) {
    this._stepService.getFile(fileName).subscribe( data => {
      const downloadURL = window.URL.createObjectURL(data);
      saveAs(downloadURL);
    });
  }

  getUserHardware(user_hardware){
    return user_hardware.map(obj => obj.name);
  }

  getEmpTypeText(type) {
    if (type === 'FULL_TIME') {
      return 'Full time';
    } else {
      return 'Part time';
    }
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

}
