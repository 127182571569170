<div class="full-screen">
  <app-admin-header></app-admin-header>

  <div class="container main-container">

    <div class="row mt-2">
      <div class="col-6" align="left">
        <button id="back_page" type="button" class="btn items-button" (click)="redirect('admin-menu')">
          <fa-icon class="" [icon]="['fas','arrow-circle-left']"></fa-icon> Back
        </button>
      </div>

      <div class="col-6" align="right">
        <button id="user_page" type="button" class="btn items-button" (click)="redirect('admin-team-add')">Create team</button>
      </div>
    </div>

    <div class="row items-row">
      <div class="col-12">
        <h5><b>All teams</b></h5>
      </div>
    </div>

    <div class="row group-menu-item"  *ngFor="let team of teams" [routerLink]="['/admin-team', team.id]" routerLinkActive="active">
      <div class="col-3" align="center">
        <fa-icon class="icon fa-2x" [icon]="['fas', 'users']"></fa-icon>
        <p>{{team.members}}</p>
      </div>

      <div class="col-9">
        <h4>{{team.name}}</h4>
        <p> {{team.description}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12"></div>
    </div>

    <router-outlet name="detail"></router-outlet>

    <div class="row row-footer"></div>
  </div>
</div>
