import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UnitFirstStepsComponent} from './components/main-menu/components/unit-first-steps/unit-first-steps.component';
import {MainMenuComponent} from './components/main-menu/main-menu.component';
import {UnitTeamAndIComponent} from './components/main-menu/components/unit-team-and-i/unit-team-and-i.component';
import {UnitUsefulLinksComponent} from './components/main-menu/components/unit-useful-links/unit-useful-links.component';
import {UnitCompanyCultureComponent} from './components/main-menu/components/unit-company-culture/unit-company-culture.component';
import {UnitCompanyCultureAwardComponent} from './components/main-menu/components/unit-company-culture/components/unit-company-culture-award/unit-company-culture-award.component';
import {MainGiftsComponent} from './components/main-gifts/main-gifts.component';
import {BeerGiftDetailComponent} from './components/main-gifts/components/beer-gift-detail/beer-gift-detail.component';
import {FunFactComponent} from './components/main-gifts/components/fun-fact/fun-fact.component';
import {WelcomePageComponent} from './components/main-welcome-info/welcome-page/welcome-page.component';
import {AttetionPageComponent} from './components/main-welcome-info/attetion-page/attetion-page.component';
import {InstructionsPageComponent} from './components/main-welcome-info/instructions-page/instructions-page.component';
import {MainErrorPageComponent} from './components/main-error-page/main-error-page.component';

import { UnitCompanyCultureTestDressCodeComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-dress-code/unit-company-culture-test-dress-code.component';
import { UnitCompanyCultureTestCompanyInfoComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-company-info/unit-company-culture-test-company-info.component';
import { UnitCompanyCultureTestLanguageComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-language/unit-company-culture-test-language.component';
import { UnitCompanyCultureTestFemaleComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-female/unit-company-culture-test-female.component';
import { UnitCompanyCultureTestAverageAgeComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-average-age/unit-company-culture-test-average-age.component';
import { UnitCompanyCultureTestPopularNameComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-popular-name/unit-company-culture-test-popular-name.component';
import { UnitCompanyCultureTestJobTitleComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-job-title/unit-company-culture-test-job-title.component';
import { UnitCompanyCultureTestPopularWomanNameComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-popular-woman-name/unit-company-culture-test-popular-woman-name.component';
import { UnitCompanyCultureTestBranchComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-branch/unit-company-culture-test-branch.component';
import { UnitCompanyCultureTestNationalitiesComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-nationalities/unit-company-culture-test-nationalities.component';
import { UnitCompanyCultureTestEmployeesComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-employees/unit-company-culture-test-employees.component';
import {UnitOurTopicsComponent} from './components/main-menu/components/unit-our-topics/unit-our-topics.component';
import {HowToUseComponent} from './components/main-welcome-info/how-to-use/how-to-use.component';
import {UnitLetsRideTogetherComponent} from './components/main-menu/components/unit-lets-ride-together/unit-lets-ride-together.component';
import {LoginComponent} from './components/login/login.component';
import {LoginAdminComponent} from './components/admin/login-admin/login-admin.component';
import {IsAuthGuard} from './guards/is-auth.guard';
import {AdminMainMenuComponent} from './components/admin/admin-main-menu/admin-main-menu.component';
import {UsersComponent} from './components/admin/admin-main-menu/components/users/users.component';
import {TeamsComponent} from './components/admin/admin-main-menu/components/teams/teams.component';
import {UserDetailComponent} from './components/admin/admin-main-menu/components/users/components/user-detail/user-detail.component';
import {NewUserComponent} from './components/admin/admin-main-menu/components/users/components/new-user/new-user.component';
import {TeamDetailComponent} from './components/admin/admin-main-menu/components/teams/components/team-detail/team-detail.component';
import {NewTeamComponent} from './components/admin/admin-main-menu/components/teams/components/new-team/new-team.component';
import {
  NewTeamMemberComponent
} from './components/admin/admin-main-menu/components/teams/components/new-team-member/new-team-member.component';
import {
  NewTeamFunFactComponent
} from './components/admin/admin-main-menu/components/teams/components/new-team-fun-fact/new-team-fun-fact.component';
import {LoginViewerComponent} from './components/admin/login-viewer/login-viewer.component';
import {IsAdminGuard} from './guards/is-admin.guard';
import {IsViewerOrAdminGuard} from './guards/is-viewer-or-admin.guard';
import {IsUserGuard} from './guards/is-user.guard';
import {UserActionsComponent} from './components/admin/admin-main-menu/components/users/components/user-actions/user-actions.component';
import {
  NewUserActionComponent
} from './components/admin/admin-main-menu/components/users/components/new-user-action/new-user-action.component';
import {
  UserEditComponent
} from './components/admin/admin-main-menu/components/users/components/user-edit/user-edit.component';
import {
  TeamDetailAuthComponent
} from './components/admin/admin-main-menu/components/teams/components/team-detail-auth/team-detail-auth.component';


const routes: Routes = [
  {path: 'first-steps',          component: UnitFirstStepsComponent,                         canActivate: [IsUserGuard]},
  {path: 'gifts',                component: MainGiftsComponent,                              canActivate: [IsUserGuard]},
    {path: 'gift-beer-detail',   component: BeerGiftDetailComponent,                         canActivate: [IsUserGuard]},
    {path: 'gift-fun-fact',      component: FunFactComponent,                                canActivate: [IsUserGuard]},
  {path: 'culture',              component: UnitCompanyCultureComponent,                     canActivate: [IsUserGuard]},
    {path: 'culture-dress-code', component: UnitCompanyCultureTestDressCodeComponent,        canActivate: [IsUserGuard]},
    {path: 'company-info',       component: UnitCompanyCultureTestCompanyInfoComponent,      canActivate: [IsUserGuard]},
    {path: 'language',           component: UnitCompanyCultureTestLanguageComponent,         canActivate: [IsUserGuard]},
    {path: 'female',             component: UnitCompanyCultureTestFemaleComponent,           canActivate: [IsUserGuard]},
    {path: 'average-age',        component: UnitCompanyCultureTestAverageAgeComponent,       canActivate: [IsUserGuard]},
    {path: 'popular-name',       component: UnitCompanyCultureTestPopularNameComponent,      canActivate: [IsUserGuard]},
    {path: 'job-title',          component: UnitCompanyCultureTestJobTitleComponent,         canActivate: [IsUserGuard]},
    {path: 'popular-woman-name', component: UnitCompanyCultureTestPopularWomanNameComponent, canActivate: [IsUserGuard]},
    {path: 'branch',             component: UnitCompanyCultureTestBranchComponent,           canActivate: [IsUserGuard]},
    {path: 'nationalities',      component: UnitCompanyCultureTestNationalitiesComponent,    canActivate: [IsUserGuard]},
    {path: 'employees',          component: UnitCompanyCultureTestEmployeesComponent,        canActivate: [IsUserGuard]},
    {path: 'culture-award',      component: UnitCompanyCultureAwardComponent,                canActivate: [IsUserGuard]},
  {path: 'links',                component: UnitUsefulLinksComponent,                        canActivate: [IsUserGuard]},
  {path: 'topics',               component: UnitOurTopicsComponent,                          canActivate: [IsUserGuard]},
  {path: 'team',                 component: UnitTeamAndIComponent,                           canActivate: [IsUserGuard]},
  {path: 'lets-ride-together',   component: UnitLetsRideTogetherComponent,                   canActivate: [IsUserGuard]},
    {path: 'welcome',            component: WelcomePageComponent,                            canActivate: [IsUserGuard]},
    {path: 'attention',          component: AttetionPageComponent,                           canActivate: [IsUserGuard]},
    {path: 'instructions',       component: InstructionsPageComponent,                       canActivate: [IsUserGuard]},
    {path: 'how-to-use',         component: HowToUseComponent,                               canActivate: [IsUserGuard]},

  {path: 'login-user',           component: LoginComponent},
  {path: 'login-admin',          component: LoginAdminComponent},
  {path: 'login-viewer',         component: LoginViewerComponent},

  {path: 'admin-menu',           component: AdminMainMenuComponent,                          canActivate: [IsAdminGuard]},
  {path: 'admin-users',          component: UsersComponent,                                  canActivate: [IsAdminGuard]},
  {path: 'admin-user/:id',       component: UserDetailComponent,                             canActivate: [IsViewerOrAdminGuard]},
  {path: 'admin-user-edit/:id',  component: UserEditComponent,                               canActivate: [IsAdminGuard]},
  {path: 'admin-user-add',       component: NewUserComponent,                                canActivate: [IsAdminGuard]},
  {path: 'admin-user-actions/:id',     component: UserActionsComponent,                      canActivate: [IsAdminGuard]},
  {path: 'admin-user-add-action/:id',  component: NewUserActionComponent,                    canActivate: [IsAdminGuard]},
  {path: 'admin-teams',          component: TeamsComponent,                                  canActivate: [IsAdminGuard]},
  {path: 'admin-teams-auth/:teamId/:token',  component: TeamDetailAuthComponent                          },
  {path: 'admin-team/:id',       component: TeamDetailComponent,                             canActivate: [IsAdminGuard]},
  {path: 'admin-team-add',       component: NewTeamComponent,                                canActivate: [IsAdminGuard]},

  {path: 'admin-team-add-member/:id', component: NewTeamMemberComponent,                     canActivate: [IsAdminGuard]},
  {path: 'admin-team-add-fact/:id',   component: NewTeamFunFactComponent,                    canActivate: [IsAdminGuard]},

  {path: '',                     component: MainMenuComponent,                               canActivate: [IsUserGuard]},
  {path: '**', pathMatch: 'full', component: MainErrorPageComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
