import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Step, UserData} from '../../../../interfaces/interfaces';
import {StepService} from '../../../../services/step.service';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent implements OnInit {

  itemStepID: string;
  userData: UserData;
  mailText: string;

  constructor(public userService: UserService,
              public stepService: StepService,
              public router: Router,
              public route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  isStepCompleted() {
    return this.userService.getUserStepStatus(this.itemStepID) === 2;
  }

  redirect(path: string){
    this.router.navigate([path]);
  }

  openNewPage(path: string) {
    window.open(path, '_blank');
  }

  mailTo(){
    this.mailText = 'mailto:ersteDigitalInnovations@erstegroup.com?subject=Fun Onboarding';
    window.location.href = this.mailText;
  }

  setStepComplete(navigate: boolean, navigatePath: string) {
    const step_id = this.userService.getActUserData().user_steps.find(step => step.step_id == this.itemStepID).id;

    this.stepService.setStepComplete(step_id).subscribe((data: UserData) => {
      if (data) {
        this.userData = data;
        this.userService.saveActUser(data);
      }

      if (navigate) {
        this.router.navigate([navigatePath]);
      }
    });
  }
}
