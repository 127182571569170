<div class="full-screen">

  <div class="header">
    <div class="container">
      <div class="row items-row-narrow-title">
        <app-header></app-header>
      </div>
    </div>
  </div>

  <div class="main-screen">

    <div class="container">
      <div class="row items-row-narrow-no">
        <div class="col-12 items-col-narrow-no-img" align="center">
          <img class="" src="./../../../../assets/welcome.png" alt="image" />
        </div>
      </div>

      <div class="row items-row-narrow-title">
        <div class="col-12 items-col text-center">
          <form>
            <div class="form-group">
              <label for="name"><b>Write you surname</b></label>
              <input type="text" class="form-control mt-3" id="name" [(ngModel)]="user_name" placeholder="Username" name="name">
            </div>

            <div *ngIf="userNotFound" class="alert alert-primary mt-2" role="alert">
              Please enter a valid name.
            </div>

            <button type="button" class="btn items-button" (click)="submit()">Continue</button>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">

  </div>
</div>




