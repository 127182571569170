import { Component, OnInit } from '@angular/core';
import {UserData} from '../../../interfaces/interfaces';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {faHome, faUser} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {
  userData: UserData;

  constructor(private library: FaIconLibrary,
              private router: Router,
              private userService: UserService) {

    library.addIcons(faUser);
    library.addIcons(faHome);
  }

  ngOnInit(): void {
    this.loadUserData();
    this.subsNewUserData();
  }

  loadUserData() {
    this.userData = this.userService.getActUserData();
  }

  subsNewUserData() {
    this.userService.userDataActNotifier$.subscribe( (userData: UserData) => {
      this.userData = userData;
    });
  }

  redirect(path: string) {
    this.router.navigate([path]);
  }
}
