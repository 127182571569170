import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { UnitFirstStepsComponent } from './components/main-menu/components/unit-first-steps/unit-first-steps.component';
import { UnitCompanyCultureComponent } from './components/main-menu/components/unit-company-culture/unit-company-culture.component';
import { UnitUsefulLinksComponent } from './components/main-menu/components/unit-useful-links/unit-useful-links.component';
import { UnitTeamAndIComponent } from './components/main-menu/components/unit-team-and-i/unit-team-and-i.component';
import { UnitCompanyCultureAwardComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-award/unit-company-culture-award.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { MainGiftsComponent } from './components/main-gifts/main-gifts.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import { UnitComponent } from './components/main-menu/components/unit/unit.component';
import { BeerGiftDetailComponent } from './components/main-gifts/components/beer-gift-detail/beer-gift-detail.component';
import {FunFactComponent} from './components/main-gifts/components/fun-fact/fun-fact.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { WelcomePageComponent } from './components/main-welcome-info/welcome-page/welcome-page.component';
import { AttetionPageComponent } from './components/main-welcome-info/attetion-page/attetion-page.component';
import { InstructionsPageComponent } from './components/main-welcome-info/instructions-page/instructions-page.component';
import { HeaderComponent } from './components/main-welcome-info/header/header.component';
import { MainErrorPageComponent } from './components/main-error-page/main-error-page.component';
import { UnitCompanyCultureTestComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test/unit-company-culture-test.component';
import { UnitCompanyCultureTestDressCodeComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-dress-code/unit-company-culture-test-dress-code.component';
import { UnitCompanyCultureTestCompanyInfoComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-company-info/unit-company-culture-test-company-info.component';
import { UnitCompanyCultureTestLanguageComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-language/unit-company-culture-test-language.component';
import { UnitCompanyCultureTestFemaleComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-female/unit-company-culture-test-female.component';
import { UnitCompanyCultureTestAverageAgeComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-average-age/unit-company-culture-test-average-age.component';
import { UnitCompanyCultureTestPopularNameComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-popular-name/unit-company-culture-test-popular-name.component';
import { UnitCompanyCultureTestJobTitleComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-job-title/unit-company-culture-test-job-title.component';
import { UnitCompanyCultureTestPopularWomanNameComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-popular-woman-name/unit-company-culture-test-popular-woman-name.component';
import { UnitCompanyCultureTestBranchComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-branch/unit-company-culture-test-branch.component';
import { UnitCompanyCultureTestNationalitiesComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-nationalities/unit-company-culture-test-nationalities.component';
import { UnitCompanyCultureTestEmployeesComponent } from './components/main-menu/components/unit-company-culture/components/unit-company-culture-test-employees/unit-company-culture-test-employees.component';
import { UnitOurTopicsComponent } from './components/main-menu/components/unit-our-topics/unit-our-topics.component';
import { UnitHeaderComponent } from './components/main-menu/components/unit-header/unit-header.component';
import { HowToUseComponent } from './components/main-welcome-info/how-to-use/how-to-use.component';
import { UnitLetsRideTogetherComponent } from './components/main-menu/components/unit-lets-ride-together/unit-lets-ride-together.component';
import { LoginComponent } from './components/login/login.component';
import {WithCredentialsInterceptor} from './interceptors/with-credentials.interceptor';
import { LoginAdminComponent } from './components/admin/login-admin/login-admin.component';
import { AdminHeaderComponent } from './components/admin/admin-header/admin-header.component';
import { AdminMainMenuComponent } from './components/admin/admin-main-menu/admin-main-menu.component';
import { UsersComponent } from './components/admin/admin-main-menu/components/users/users.component';
import { NewUserComponent } from './components/admin/admin-main-menu/components/users/components/new-user/new-user.component';
import { TeamsComponent } from './components/admin/admin-main-menu/components/teams/teams.component';
import { NewTeamComponent } from './components/admin/admin-main-menu/components/teams/components/new-team/new-team.component';
import { UserDetailComponent } from './components/admin/admin-main-menu/components/users/components/user-detail/user-detail.component';
import { TeamDetailComponent } from './components/admin/admin-main-menu/components/teams/components/team-detail/team-detail.component';
import { NewTeamMemberComponent } from './components/admin/admin-main-menu/components/teams/components/new-team-member/new-team-member.component';
import { NewTeamFunFactComponent } from './components/admin/admin-main-menu/components/teams/components/new-team-fun-fact/new-team-fun-fact.component';
import { LoginViewerComponent } from './components/admin/login-viewer/login-viewer.component';
import { UserActionsComponent } from './components/admin/admin-main-menu/components/users/components/user-actions/user-actions.component';
import { NewUserActionComponent } from './components/admin/admin-main-menu/components/users/components/new-user-action/new-user-action.component';
import { UserEditComponent } from './components/admin/admin-main-menu/components/users/components/user-edit/user-edit.component';
import { TeamDetailAuthComponent } from './components/admin/admin-main-menu/components/teams/components/team-detail-auth/team-detail-auth.component';

@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    UnitFirstStepsComponent,
    UnitCompanyCultureComponent,
    UnitUsefulLinksComponent,
    UnitTeamAndIComponent,
    UnitCompanyCultureAwardComponent,
    MainGiftsComponent,
    UnitComponent,
    BeerGiftDetailComponent,
    FunFactComponent,
    MainHeaderComponent,
    WelcomePageComponent,
    AttetionPageComponent,
    InstructionsPageComponent,
    HeaderComponent,
    MainErrorPageComponent,
    UnitCompanyCultureTestComponent,
    UnitCompanyCultureTestDressCodeComponent,
    UnitCompanyCultureTestCompanyInfoComponent,
    UnitCompanyCultureTestLanguageComponent,
    UnitCompanyCultureTestFemaleComponent,
    UnitCompanyCultureTestAverageAgeComponent,
    UnitCompanyCultureTestPopularNameComponent,
    UnitCompanyCultureTestJobTitleComponent,
    UnitCompanyCultureTestPopularWomanNameComponent,
    UnitCompanyCultureTestBranchComponent,
    UnitCompanyCultureTestNationalitiesComponent,
    UnitCompanyCultureTestEmployeesComponent,
    UnitOurTopicsComponent,
    UnitHeaderComponent,
    HowToUseComponent,
    UnitLetsRideTogetherComponent,
    LoginComponent,
    LoginAdminComponent,
    AdminHeaderComponent,
    AdminMainMenuComponent,
    UsersComponent,
    NewUserComponent,
    TeamsComponent,
    NewTeamComponent,
    UserDetailComponent,
    TeamDetailComponent,
    NewTeamMemberComponent,
    NewTeamFunFactComponent,
    LoginViewerComponent,
    UserActionsComponent,
    NewUserActionComponent,
    UserEditComponent,
    TeamDetailAuthComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  providers: [
    HttpClientModule,
    { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
