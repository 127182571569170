<app-unit-company-culture-test
  [test_steps]="11"
  [test_step]="7"
  [test_question]="'7. Most common job title?'"
  [test_img_src_1]="'./assets/items-img/culture_test_time/BusinessA.png'"
  [test_img_src_1_correct_anw]="0"
  [test_img_src_2]="'./assets/items-img/culture_test_time/Software.png'"
  [test_img_src_2_correct_anw]="1"
  [test_success_redirect]="'popular-woman-name'"
></app-unit-company-culture-test>
