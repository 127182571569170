import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-unit-company-culture-test',
  templateUrl: './unit-company-culture-test.component.html',
  styleUrls: ['./unit-company-culture-test.component.scss']
})
export class UnitCompanyCultureTestComponent implements OnInit {

  @Input() test_step;
  @Input() test_steps;
  @Input() test_question;

  @Input() test_img_src_1;
  @Input() test_img_src_2;

  @Input() test_img_src_1_correct_anw;
  @Input() test_img_src_2_correct_anw;

  @Input() test_success_redirect;

  answerImg1 = false;
  answerImg2 = false;

  constructor(private router: Router,
              public library: FaIconLibrary) {

    library.addIcons(faHome);
  }

  ngOnInit(): void {
  }

  validUserAnswer(answerID) {
    if (answerID === 1) {
      this.router.navigate([this.test_success_redirect]);
    }
  }

  redirect(path: string){
    this.router.navigate([path]);
  }
}
