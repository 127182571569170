import { Component, OnInit } from '@angular/core';
import {Team} from '../../../../../interfaces/interfaces';
import {TeamService} from '../../../../../services/team.service';
import {Router} from '@angular/router';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faArrowCircleLeft, faUsers} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  teams: Team[] = [];

  constructor(private _teamService: TeamService,
              private _router: Router,
              private _library: FaIconLibrary) {

    _library.addIcons(faUsers);
    _library.addIcons(faArrowCircleLeft);
  }

  ngOnInit(): void {
    this._teamService.getTeams().subscribe( data => {
      if (data) {
        this.teams = data;
      }
    });
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

}
