import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TeamService} from '../../../../../../../services/team.service';
import {Location, Team, User, UserData, UserPosition} from '../../../../../../../interfaces/interfaces';
import {UserService} from '../../../../../../../services/user.service';
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  userForm: FormGroup;
  teams: Team[] = [];
  locations: Location[] = [];
  userPositions: UserPosition[] = [];

  constructor(private _router: Router,
              private _teamService: TeamService,
              private _userService: UserService,
              private _library: FaIconLibrary,
              private _formBuilder: FormBuilder) {

    _library.addIcons(faArrowCircleLeft);
  }

  ngOnInit(): void {
    this.userForm = this._formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      manager_email: ['', [Validators.required, Validators.email]],
      team: ['', [Validators.required]],
      position: ['', [Validators.required]],
      coupon_url: ['', []],
      first_day: ['', [Validators.required]],
      emp_type: ['', [Validators.required]],
      location: ['', [Validators.required]],
    });

    this._teamService.getTeams().subscribe( data => {
      if (data) {
        this.teams = data;
        this.setDataFormDefaultValues();
      }
    });

    this._userService.getAllUserPositions().subscribe( data => {
      this.userPositions = data;
      this.setDataFormDefaultValues();
    });

    this._userService.getAllLocations().subscribe( data => {
      this.locations = data;
    });
  }

  createUser() {
    const formData: FormData = new FormData();

    if (this.userForm.invalid) {
      return;
    }

    formData.append('first_name', this.userForm.controls.first_name.value);
    formData.append('last_name', this.userForm.controls.last_name.value);
    formData.append('email', this.userForm.controls.email.value);
    formData.append('manager_email', this.userForm.controls.manager_email.value);
    formData.append('role', 'USER');
    formData.append('team_id', this.userForm.controls.team.value);
    formData.append('position_id', this.userForm.controls.position.value);
    formData.append('coupon_url', this.userForm.controls.coupon_url.value);
    formData.append('first_day', this.userForm.controls.first_day.value);
    formData.append('location', this.userForm.controls.location.value);
    formData.append('emp_type', this.userForm.controls.emp_type.value);

    this._userService.createNewUser(formData).subscribe((resp: User) => {
        this._router.navigate(['/admin-user', resp.external_id]);
      });
  }

  setDataFormDefaultValues() {
    if (this.teams && this.teams.length > 0) {
      this.userForm.controls.team.setValue(this.teams[0].id);
    }

    if (this.userPositions && this.userPositions.length > 0) {
      this.userForm.controls.position.setValue(this.userPositions[0].id);
    }
  }

  redirect(path: string) {
    this._router.navigate([path]);
  }

  get formControls() {
    return this.userForm.controls;
  }
}
